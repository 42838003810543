import React, { useEffect, useState } from 'react';
import { Select, Form, Input, Button, message, Modal } from 'antd';
import './addEdit.css';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
import {
	addAddress,
	updateAddress,
	setAddress,
	getAddresses,
} from '../../reducer/userDetailsReducer';
import { checkUserSession, checkUserStatus } from '../../reducer/userReducer';
import successImg from '../../images/checkmark.png';
import { useTranslation } from 'react-i18next';
import SubHeader from '../../components/SubHeader';
import axiosClient from '../../utils/axiosClient';

const AddUpdateAddress = () => {
	const navigate = useNavigate();
	// const dispatch = useDispatch();
	let location = useLocation();
    // const { t } = useTranslation();
    const t=(string)=>string;

	const [form] = Form.useForm();
	const [params] = useSearchParams();
	const editAddress = params.get('edit');
	const checkout = params.get('checkout');
	const [submit, setSubmit] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const localtoken = localStorage.getItem('user-token');
	// const localtoken = localStorage.getItem("user-token");
	const currentAddress = location.state?.address;
	// const { error, loading } = useSelector((state) => state.userDetails);
	const getPreviousLocation = location.state?.location.pathname;
	const [previousPage, setPreviousPage] = useState(false);
	const [errorName, setErrorName] = useState(' ');
	const [errorNumber, setErrorNumber] = useState(' ');
	const [errorPostcode, setErrorPostcode] = useState('');
	const [errorCity, setErrorCity] = useState('');
	const [name, setName] = useState('');
	const [number, setNumber] = useState('');
	const [postcode, setPostcode] = useState('');
	const [city, setCity] = useState('');
	const [errorMsg, setErrorMsg] = useState('');

	useEffect(() => {
		setPreviousPage(getPreviousLocation);
	}, [getPreviousLocation]);

	const handleDoneClick = () => {
		if (getPreviousLocation === '/cartInfo') {
			navigate('/deliveryAddress');
		} else {
			navigate('/address');
		}
	};

	// useEffect(() => {
	// 	dispatch(checkUserSession({ token: localtoken }));
	// 	dispatch(getAddresses());
	// }, []);

	// useEffect(() => {
	// 	if (error && submit) {
	// 		message.error(error);
	// 		setIsModalOpen(false);
	// 		setIsSuccess(false);
	// 		setSubmit(false);
	// 	}
	// }, [error, submit]);

	// const onFinish = (values) => {
	//   let data = { ...values };
	//   if(!data) {
	//     setErrorMsg("Please fill in all the information above");
	//   } else {
	//     setIsSuccess(true);
	//     dispatch(addAddress({data}));
	//   }
	// };

	const onFinish = async (values) => {
		if (!values) {
      setIsSuccess(false);
			setErrorMsg('Please fill in all the information above');
		}
		try {
			const res = await axiosClient.post('/address/create', {
				newAddr: values,
			});
			if (res) {
				setIsSuccess(true);
			}
		} catch (e) {
			console.log('error', e);
		}
	};

	const isName = (e) => {
		var regex = /^[a-zA-Z ]+( [a-zA-Z ]+)*$/;
		// /^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/;
		setName(e.target.value);
		if (!regex.test(e.target.value)) {
			setErrorName('Invalid name format');
		} else {
			setErrorName('');
		}
		if (!e.target.value) {
			setErrorName('');
		}
	};

	const isNumber = (e) => {
		// eslint-disable-next-line no-useless-escape
		let regNumber = /^(01)[0-46-9]([0-9]){7,8}$/;
		if (!regNumber.test(e.target.value)) {
			setErrorNumber('Invalid number format');
		} else {
			setErrorNumber('');
			setNumber(e.target.value);
		}
		if (!e.target.value) {
			setErrorNumber('');
		}
	};

	const isCity = (e) => {
		var regCity = /^[a-zA-Z ]+( [a-zA-Z ]+)*$/;
		if (!regCity.test(e.target.value)) {
			setErrorCity('Invalid city name');
		} else {
			setErrorCity('');
			setCity(e.target.value);
		}
		if (!e.target.value) {
			setErrorCity('');
		}
	};

	const isPostcode = (e) => {
		// console.log(e.target.value);
		let regPostcode = /^\d{5}$/;
		if (!regPostcode.test(e.target.value)) {
			setErrorPostcode('Invalid postcode');
		} else {
			setErrorPostcode('');
			setPostcode(e.target.value);
		}
		if (!e.target.value) {
			setErrorPostcode('');
		}
	};

	return (
		<div>
			<SubHeader setPreviousPage={-1} />
			<div className="ml-3 mr-3 mt-2" style={{ paddingBottom: '30px' }}>
				<div className="">
					<p className="delivery-details-font">{('Delivery Details')}</p>
					<p>Enter your address for delivery.</p>
				</div>

				<div>
					<div>
						<Form
							layout="vertical"
							name="add_update_address"
							form={form}
							onFinish={onFinish}
							autoComplete="off"
						>
							<div>
								<div className="mx-5 mt-2">
									<Form.Item
										name="name"
										label={
											<label className="form-item-label">{t('Name')}</label>
										}
										rules={[
											{
												message: t('inputName'),
											},
										]}
									>
										<Input
											type="text"
											placeholder={t('Enter your name')}
											required
											onChange={isName}
										/>
									</Form.Item>
									{errorName ? (
										<>
											{' '}
											<div
												style={{
													color: 'red',
													marginTop: '-5%',
													textAlign: 'left',
												}}
											>
												{errorName}
											</div>
											<br />
										</>
									) : null}

									<Form.Item
										name="phone"
										label={
											<label className="form-item-label">
												{t('Mobile Number')}
											</label>
										}
									>
										<Input
											type="number"
											placeholder="Enter your mobile number"
											required
											onChange={isNumber}
										/>
									</Form.Item>
									{/* {errorNumber ? (
										<>
											{' '}
											<div
												style={{
													color: 'red',
													marginTop: '-5%',
													textAlign: 'left',
												}}
											>
												{errorNumber}
											</div>
											<br />
										</>
									) : null} */}

									<Form.Item
										name="line1"
										label={
											<label className="form-item-label">{t('Address')}</label>
										}
										rules={[
											{
												message: t('inputAddress'),
											},
										]}
										style={{ color: 'red' }}
									>
										<Input
											type="text"
											placeholder={t('Enter your address (line 1)')}
											required
										/>
									</Form.Item>
									<Form.Item name="line2" label="">
										<Input
											type="text"
											placeholder={t('Enter your address (line 2)')}
										/>
									</Form.Item>

									<div
										className="d-flex"
										style={{ justifyContent: 'space-evenly', gap: '20px' }}
									>
										<Form.Item
											name="city"
											label={
												<label className="form-item-label">{t('City')}</label>
											}
											rules={[
												{
													// required: true,
													message: t('inputCity'),
												},
											]}
											style={{ minWidth: '150px' }}
											className="w-100 ms-2"
										>
											<Input
												type="text"
												placeholder="City"
												required
												onChange={isCity}
											/>
										</Form.Item>

										<Form.Item
											name="postcode"
											label={
												<label className="form-item-label">
													{t('ZIP/Postcode')}
												</label>
											}
											className="w-100"
											rules={[
												{
													message: t('inputPostcode'),
												},
											]}
										>
											<Input
												type="number"
												placeholder={t('58200')}
												required
												onChange={isPostcode}
											/>
										</Form.Item>
									</div>
									{errorCity ? (
										<div style={{ color: 'red', textAlign: 'left' }}>
											{errorCity}
										</div>
									) : null}
									{errorPostcode ? (
										<div style={{ color: 'red', textAlign: 'left' }}>
											{errorPostcode}
										</div>
									) : null}
								</div>
								<div style={{ height: '15vh' }}></div>
								<Button
									// loading={loading}
									htmlType="submit"
									className="submit-btn"
								>
									{t('Submit')}
								</Button>
							</div>
						</Form>
					</div>
				</div>

				<div>
					<Modal
						open={isSuccess}
						centered
						header={null}
						footer={null}
						onCancel={() => setIsSuccess(false)}
						closable={false}
						width={330}
					>
						<div className="text-center">
							<img
								style={{ width: '20%' }}
								src={successImg}
								alt=""
								className="submit-success-icon"
							></img>
							<div className="submit-receipt-success-header">Success!</div>
							<div className="submit-pending-modal-font mt-1">
								<p className="text-center">
									Address has been successfully saved.
								</p>
							</div>
							<Button
								className="receipt-success-button mt-1"
								style={{ backgroundColor: '#274193', color: 'white' }}
								onClick={handleDoneClick}
							>
								Done
							</Button>
						</div>
					</Modal>
				</div>
			</div>
		</div>
	);
};

export default AddUpdateAddress;
