import "../../css/Login.css";
import React from "react";
import LoginForm from "../../components/forms/LoginForm";
// import Whatsapp from "../../components/Whatsapp";
import logo from "../../images/Jomkik logo.svg";
import loginBackground from "../../images/loginBackground.svg";
import brandMocost from "../../images/loginMocost.svg";
import signinMocost from "../../images/signinMocost.svg";
// import { useDispatch } from 'react-redux';
// import { useSearchParams } from 'react-router-dom';

const LoginPage = () => {
  // const dispatch = useDispatch();
  // const [searchParams] = useSearchParams();
  // const [error, setError] = useState(false);
  // const id = searchParams.get('store');

  return (
    <div className="text-center" style={{
      position: 'relative',

      minWidth: '280px',//galaxy fold

      backgroundImage: `url(${loginBackground})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',

      height: '100vh',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      {/* wrapper */}
      <div style={{
        width: '100%',
      }}>

      <div className="logo-position">
        {/* Jomkik logo */}
        <img
          src={logo}
          alt="jomkik"
		  style={{width:'45%', marginBottom:'4%'}}
        />
        {/* <Whatsapp /> */}
      </div>
      <img src={brandMocost} alt="pic" style={{width:'80%'}} />
      <div>
        <div style={{ textAlign: "center",  }}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'end',
            flexDirection: 'row',
            alignContent: 'center',

            marginBottom: '3%',
          }}>
          <img src={signinMocost} alt="pic" style={{width:'18%'}} />
          <h1
            style={{
              fontFamily: 'Poppins',
              textTransform: 'uppercase',
              fontSize: '25px',
            color:'#264c96'
          }}
          >Sign In</h1>
          </div>
          <LoginForm
            formConfig={{
              label: "Phone Number",
              placeholder: "Enter Your Phone Number",
              layout: "vertical",
              btnText: "Login Now",
            }}
          />
        </div>
      </div>
      </div>
      </div>
      
  );
};

export default LoginPage;
