import React, { useState, useEffect } from "react";
import { Space } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./profile.css";
import UserFooter from "../../components/UserFooter";
import MainHeader from "../../components/MainHeader";
// import { fetchUserProfileIfNotExist } from "../../redux/user/userActions";
import { UserOutlined } from "@ant-design/icons";
import pdf from "../../images/pdf/JomKiK Lunar Luck Bonanza Spin and Win T&C and Privacy.pdf";
import axiosClient from "../../utils/axiosClient";
// const { Panel } = Collapse;

const ProfilePage = () => {
  const t = (string) => string;
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [userData, setUserData] = useState();
  const [error, setError] = useState();

  const check = async () => {
    try {
      const res = await axiosClient.get("/users/details");
      // console.log("res",res);
      setUserData(res.data.data.personalInfo);
    } catch (error) {
      setError(error.res.data.error);
      console.error("error", error);
    }
  };

  useEffect(() => {
    check();
  }, []);

  return (
    <div>
      <div>
        <MainHeader />

        <div className="mt-3 ml-2 mr-2" style={{ marginBottom: "30%" }}>
          <div className="user-container">
            <div className="text-center" style={{ paddingTop: "5%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "0.25rem",
                }}
              >
                <UserOutlined />
                <span className="font-weight-600" style={{ fontSize: "20px" }}>
                  {userData?.name}
                </span>
              </div>
              <p className="text-center" style={{ fontSize: "14px" }}>
                @{userData?.referralCode}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                gap: "5rem",
                alignItems: "center",
                margin: "auto",
                paddingTop: "8%",
              }}
              className="mr-2 text-center"
            >
              <div>
                <p style={{ fontSize: "18px" }}>Token</p>
                <h1 style={{ fontSize: "18px" }}>
                  {" "}
                  {userData?.totalTokenBalance || 0}{" "}
                </h1>
              </div>
              <div>
                <p style={{ fontSize: "18px" }}>KiKs</p>
                <h1 style={{ fontSize: "18px" }}>
                  {" "}
                  {userData?.totalUnitsBalance || 0}{" "}
                </h1>
              </div>
            </div>
          </div>

          <div>
            <div>
              <Space
                direction="vertical"
                style={{ width: "100%", marginTop: "5%" }}
                size={10}
              >
                <div
                  className="redirection-box"
                  onClick={() => navigate("/personal/info")}
                >
                  <div className="profile-title-font">Personal Info</div>
                  {/* <img src={backArrow} alt="backArrow" className="arrow-pos" /> */}
                </div>

                <div
									className="redirection-box"
									onClick={() => navigate('/address')}
								>
									<div className="t-black profile-title-font">
										{t('Delivery')} {t('Details')}
									</div>
									{/* <img src={backArrow} alt="backArrow" className="arrow-pos" /> */}
								</div>

                <div
                  className="redirection-box"
                  onClick={() => navigate("/referral-program")}
                >
                  <div className="profile-title-font">Referral Program</div>
                  {/* <img src={backArrow} alt="backArrow" className="arrow-pos" /> */}
                </div>

                <div className="redirection-box">
                  <div className="profile-title-font">
                    <a
                      href={pdf}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#274193",
                      }}
                    >
                      {" "}
                      Terms & Conditions / Privacy Policy
                    </a>
                  </div>
                  {/* <img src={backArrow} alt="backArrow" className="arrow-pos" /> */}
                </div>
              </Space>
            </div>
          </div>
        </div>
        <UserFooter type={"profilePage"} />
      </div>
    </div>
  );
};

export default ProfilePage;
