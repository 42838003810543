import React, { useState, useEffect } from "react";
import moment from "moment";
import { Form, Input, Button, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import SubHeader from "../../components/SubHeader";
import "./profile.css";
import axiosClient from "../../utils/axiosClient";

const PersonalDetails = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [submit, setSubmit] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorEmail, setErrorEmail] = useState(" ");
  const [errorName, setErrorName] = useState(" ");
  const [errorNumber, setErrorNumber] = useState(" ");
  const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [number, setNumber] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [error, setError] = useState();
  const [userData, setUserData] = useState();

  useEffect(() => {
    // dispatch(checkUserSession({token: localtoken}));
    const checkUserInfo = async () => {
      try {
        const res = await axiosClient.get("/users/details");
        setUserData(res.data.data.personalInfo);
        // console.log(res);
      } catch (error) {
        setError(error.res.data.error);
        console.log("error", error);
      }
    };
    checkUserInfo();
  }, []);

  const onFinish = async (values) => {
    try {
      const res = await axiosClient.post("/profile/update", {
        values: { ...values },
      });
      setSubmit(true);
      if (res) {
        setIsSuccess(true);
      }
    } catch (err) {
      console.error("error", err);
    }
  };

  useEffect(() => {
    if (userData) {
      form.setFieldsValue({
        name: userData?.name,
        email: userData?.email,
        phone: userData?.phone,
        dob: userData?.dob ? moment(userData?.dob).format("YYYY-MM-DD") : null,
      });
    }
  }, [userData, form]);

  const isName = (e) => {
    var regex = /^[a-zA-Z ]+( [a-zA-Z ]+)*$/;
    // /^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/;
    setName(e.target.value);
    if (!regex.test(e.target.value)) {
      setErrorName("Invalid name format");
    } else {
      setErrorName("");
    }
    if (!e.target.value) {
      setErrorName("");
    }
  };

  // const isNumber = (e) => {
  //   // eslint-disable-next-line no-useless-escape
  //   let regNumber = /^(601[0-9]{8,9})$/;
  //   if (!regNumber.test(e.target.value)) {
  //     if (e.target.value.substr(0, 3) !== "601") {
  //       setErrorNumber("Please enter phone number starting with 60");
  //     } else if (e.target.value.length !== 11 && e.target.value.length !== 12) {
  //       setErrorNumber("Invalid phone number!");
  //     }
  //   } else {
  //     setErrorNumber("");
  //     setNumber(e.target.value);
  //   }
  //   if (!e.target.value) {
  //     setErrorNumber("");
  //   }
  // };

  // const isEmail = (e) => {
  //   setEmail(e.target.value);
  //   // eslint-disable-next-line no-useless-escape
  //   let regEmail = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  //   if (!regEmail.test(e.target.value)) {
  //     setErrorEmail("Invalid email format");
  //   } else {
  //     setErrorEmail("");
  //   }
  //   if (!e.target.value) {
  //     setErrorEmail("");
  //   }
  // };

  return (
    <div>
      <SubHeader setPreviousPage={"/profile"} />

      <div className="mt-1">
        <hr />
      </div>

      <div className="ml-2 mr-2" style={{ paddingBottom: "10%" }}>
        <div className="mt-2">
          <p style={{ fontWeight: "500", fontSize: "25px" }}>Personal Info</p>
        </div>

        <div>
          <div className="mx-5">
            <Form
              layout="vertical"
              name="register"
              form={form}
              onFinish={onFinish}
              autoComplete="off"
            >
              <div className="mt-2">
                <Form.Item
                  name="name"
                  label={<label className="form-item-label">Full Name</label>}
                  rules={[
                    {
                      // required: true,
                      message: "inputName",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Full Name"
                    onChange={isName}
                  />
                </Form.Item>
                {errorName ? (
                  <>
                    {" "}
                    <div
                      style={{
                        color: "red",
                        marginTop: "-5%",
                        textAlign: "left",
                      }}
                    >
                      {errorName}
                    </div>
                    <br />
                  </>
                ) : null}
                <Form.Item
                  name="dob"
                  label={
                    <label className="form-item-label">Date of Birth</label>
                  }
                >
                  <Input
                    type="date"
                    style={{
                      width: "100%",
                      border: "1px solid #d9d9d9",
                      height: "38px",
                      borderRadius: "6px",
                      paddingLeft: "3%",
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="email"
                  label={
                    <label className="form-item-label">Email Address</label>
                  }
                  rules={[
                    {
                      // required: true,
                      message: "inputEmail",
                    },
                  ]}
                  style={{ color: "red" }}
                >
                  <Input
                    type="email"
                    placeholder="Email Address"
                    // onChange={isEmail}
                    disabled={true}
                  />
                </Form.Item>
                {errorEmail ? (
                  <>
                    {" "}
                    <div
                      style={{
                        color: "red",
                        marginTop: "-5%",
                        textAlign: "left",
                      }}
                    >
                      {errorEmail}
                    </div>
                    <br />
                  </>
                ) : null}

                <Form.Item
                  name="phone"
                  label={
                    <label className="form-item-label">Mobile Number</label>
                  }
                >
                  <Input
                    type="number"
                    placeholder="Enter your phone number"
                    // onChange={isNumber}
                    disabled={true}
                  />
                </Form.Item>
                {errorNumber ? (
                  <>
                    {" "}
                    <div
                      style={{
                        color: "red",
                        marginTop: "-5%",
                        textAlign: "left",
                      }}
                    >
                      {errorNumber}
                    </div>
                    <br />
                  </>
                ) : null}
                <div
                  style={{
                    position: "relative",
                    top: "20px",
                    left: "0",
                    width: "90%",
                  }}
                ></div>
              </div>
              {errorMsg ? (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  {errorMsg}
                </p>
              ) : null}
              <Form.Item style={{ paddingTop: "30%" }}>
                <Button
                  // loading={loading}
                  htmlType="submit"
                  className="save-btn"
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div>
          <Modal
            open={isSuccess}
            centered
            header={null}
            footer={null}
            onCancel={() => setIsSuccess(false)}
            closable={false}
            width={280}
          >
            <div className="text-center">
              <div className="update-details-success-header">Success!</div>
              <div className="mt-1 w-100">
                <p className="text-center font-16">
                  Personal info has been successfully updated
                </p>
              </div>
              <Button
                className="receipt-success-button mt-2"
                style={{ backgroundColor: "#274193" }}
                onClick={() => navigate("/profile")}
              >
                Done
              </Button>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
