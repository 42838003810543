import React, { useEffect, useState } from "react";
// import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, message } from "antd";
import referFriendImg from "../../images/referFriend.svg";
import SubHeader from "../../components/SubHeader";
import "../../css/ReferAFriend.css";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserProfileIfNotExist } from "../../redux/user/userActions";

const ReferAFriend = () => {
  // const { t } = useTranslation();
  // const navigate = useNavigate();
  // const [params] = useSearchParams();
  const dispatch = useDispatch();
  // const [data, setData] = useState();
  const { userProfile } = useSelector((state) => state.userReducer);
  const referralCode = userProfile?.personalInfo.referralCode;

  useEffect(() => {
    dispatch(fetchUserProfileIfNotExist());
  }, []);

  const clickReferralLink = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_DOMAIN}?referCode=${referralCode}`
    );
    message.success("Copied to clipboard!");
  };

  return (
    <div>
      <SubHeader setPreviousPage={-1} />

      <div className="ml-3 mr-3 mt-2" style={{ paddingBottom: "50px" }}>
        <div>
          <div className="mt-3">
            <p
              style={{
                fontSize: "30px",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              Refer a Friend
            </p>
          </div>

          {/* <div
            className="mt-3"
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "left",
            }}
          >
            <img src={HowDoesItWorkIcon} alt="" style={{ width: "20px" }} />
            <span
              style={{
                marginLeft: "10px",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              How does it work?
            </span>
          </div> */}

          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={referFriendImg}
              alt=""
              style={{
                width: "60%",
              }}
            />
          </div>

          {/* <div
            style={{
              backgroundColor: "#274193",
              display: "flex",
              height: "60px",
              borderRadius: "12px",
              justifyContent: "space-evenly",
              alignItems: "center",
              position: "relative",
              bottom: "6px",
              gap: "5px",
              opacity: "0.75"
            }}
          >
            <p
              style={{
                color: "white",
                alignItems: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                // border: "1px solid white"
              }}
              className="fontSize-16 ml-1"
            >
              https://jomkik.com/{fetchUserDetails}
            </p>
          </div> */}

          <div>
            <p className="text-center" style={{marginLeft:"10%", marginRight:'10%'}}>
              Share the referral link to your friend, and you will earn 100 KiK!
            </p>
          </div>

          {/* <div
            className="mt-1"
            style={{ display: "flex", justifyContent: "center", gap: "30px" }}
          >
            <div style={{ alignItems: "center" }}>
              <img
                src={numbering}
                alt=""
                style={{ width: "20px", height:'190px'}}
              />
            </div>
            <div>
              <div style={{ marginTop: "10px" }}>
                <p className="fontSize-16">Invite Your Friends</p>
                <p className="fontSize-16" style={{ color: "#62798A" }}>
                  Share your referral code.
                </p>
              </div>

              <div style={{ marginTop: "25px" }}>
                <p className="fontSize-16">You earn Kik fr</p>
                <p className="fontSize-14" style={{ color: "#62798A" }}>
                  Your friend will earn 10 points for
                  <br /> using your link.
                </p>
              </div>

              <div
                style={{
                  marginTop: "5px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div>
                  <p className="fontSize-16">You earn too!</p>
                  <p className="fontSize-14" style={{ color: "#62798A" }}>
                    For each referral you get 1 KiK!
                  </p>
                </div>
                <div>
                  <img
                    src={referFriendImg}
                    alt=""
                    style={{
                      width: "100px",
                      position: "relative",
                      left: "20px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div> */}
          <div style={{
            marginLeft:'10%',
            marginRight:'10%'
          }}>
          <Button className="copy-btn mt-5" onClick={clickReferralLink}>
            Copy your link
          </Button>
        </div>
        </div>
      </div>
    </div>
  );
};

export default ReferAFriend;
