import "../../css/Register.css";
import RegisterForm from "../../components/forms/RegisterForm";
import img1 from "../../images/authPage.svg";
import logo from "../../images/Jomkik logo.svg";
// import Whatsapp from "../../components/Whatsapp";
const RegisterPage = () => {

  return (
        <div className='text-center' style={{position:'relative'}}>
        <div className='logo-position'>
          {/* Jomkik logo */}
          <img src={logo} alt="jomkik" style={{ width: '50%' }} />
          {/* <Whatsapp/> */}
        </div>
        <img src={img1} alt="pic" style={{width:'70%'}} />
        <div>
        <div style={{textAlign:'center'}}>  
            <br/>  
            <h1>Sign Up</h1>
            <div className='mt-1'></div>
            <p className="mt-1 text-center">Please complete the form to proceed</p>
          <div className="mt-3">
            <RegisterForm
              formConfig={{
                label: "Number",
                placeholder: "Enter Your Phone Number",
                layout: "vertical",
              }}
            />
          </div>
          </div>
        </div>
      </div>
  );
};

export default RegisterPage;