import "../../css/Reward.css";
import {
  Button,
  Collapse,
  Input,
  Modal,
  Rate,
  Select,
  Space,
  message,
} from "antd";
import { CopyOutlined } from "@ant-design/icons";
import MainHeader from "../../components/MainHeader";
import React, { useEffect, useState } from "react";
// import HowToRedeemGrab from "../../components/Modal/HowToRedeemGrab";
// import HowToRedeemTNG from "../../components/Modal/HowToRedeemTNG";
// import TermsGrab from "../../components/Modal/TermsGrab";
// import TermsTNG from "../../components/Modal/TermsTNG";
import UserFooter from "../../components/UserFooter";
// import confirmationIcon from "../../images/confirmation.svg";
import copy from "../../images/copy.png";
import delivery from "../../images/deliveryOrder.svg";
// import deliveryIcon from "../../images/deliveryIcon.svg";
// import trackOrderIcon from "../../images/trackOrderIcon.svg";
import { useNavigate } from "react-router-dom";
// import BarcodeTimer from '../../components/BarcodeTimer';
import moment from "moment";
// import Whatsapp from "../../components/Whatsapp";
import axiosClient from "../../utils/axiosClient";
import noImg from "../../images/pickup.svg";
import { formatRelative } from "date-fns";

import "../../css/Reward.css";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { checkUserSession, fetchHistory } from "../../reducer/userReducer";
import {
  createRatingReview,
  fetchRatingReview,
  getReward,
  updateVoucherUsed,
} from "../../reducer/userDetailsReducer";
import { getCart, getPointShop } from "../../reducer/userDetailsReducer";
import { useDispatch, useSelector } from "react-redux";
import confirmationIcon from "../../images/confirmation.svg";
import deliveryIcon from "../../images/deliveryIcon.svg";
import pickup from "../../images/pickup.svg";
import trackOrderIcon from "../../images/trackOrderIcon.svg";
import { CopyToClipboard as CopyToClipboardLib } from "react-copy-to-clipboard";

const { Panel } = Collapse;

const Rewards = () => {
  const navigate = useNavigate();
  // const [redeemTNG, setRedeemTNG] = useState(false);
  // const [redeemGrab, setRedeemGrab] = useState(false);
  // const [termsTNG, setTermsTNG] = useState(false);
  // const [termsGrab, setTermsGrab] = useState(false);
  // const [value, setValue] = useState("overview");
  const [clickedVoucherIndex, setClickedVoucherIndex] = useState();
  // const [rating, setRating] = useState("");
  // const [productRating, setProductRating] = useState(false);
  // const [review, setReview] = useState("");
  // const [errorMsg, setErrorMsg] = useState(false);
  // const [successMsg, setSuccessMsg] = useState(false);
  const [expandedItems, setExpandedItems] = useState([]);
  // const [redeem, setRedeem] = useState(null);
  // const [showBarcode, setShowBarcode] = useState(false);
  // const [barcodeValue, setBarcodeValue] = useState();
  // const [barcodeName, setBarcodeName] = useState();
  // const [startCountdown, setStartCountdown] = useState(false);
  // const [trackingId, setTrackingId] = useState();
  // const [rewards, setRewards] = useState();
  // const [reviewProduct, setReviewProduct] = useState();
  const [product, setProduct] = useState();
  const [voucher, setVoucher] = useState();
  // const [isUsed, setIsUsed] = useState(false);
  // const [redeemNowClicked, setRedeemNowClicked] = useState(false);
  // const [clickedVouchers, setClickedVouchers] = useState([]);
  const [forceRenderKey, setForceRenderKey] = useState(0);

  // const handleFilterChange = async (value) => {

  // 	try {
  // 		let dateFilter;

  // 		if (value === 'Filter By') {
  // 		  // If "Filter By" is selected, set dateFilter to the current month
  // 		  const currentMonth = new Date().getMonth() + 1;
  // 		  dateFilter = currentMonth.toString();
  // 		} else {
  // 		  // Use the selected value as dateFilter
  // 		  dateFilter = value;
  // 		}

  // 		const res = await axiosClient.get('/order/get', {
  // 		  params: {
  // 			dateFilter,
  // 		  },
  // 		});

  // 		setProduct(res.data.checkProduct);
  // 		setVoucher(res.data.checkVoucher);
  // 	  } catch (error) {
  //         console.error('Error fetching data:', error);
  //         // Handle error as needed
  //     }
  // }

  const items = [
    {
      key: "1",
      label: "Overview",
      value: "overview",
    },
    {
      key: "2",
      label: "Last Month",
      value: "last_month",
    },
    {
      key: "3",
      label: "Last 3 Months",
      value: "last_three_months",
    },
    {
      key: "4",
      label: "Last 6 Months",
      value: "last_six_months",
    },
  ];

  useEffect(() => {
    // Fetch data for the current month when the component mounts
    fetchDataForCurrentMonth();
  }, []); // Empty dependency array ensures this effect runs only once, equivalent to componentDidMount

  useEffect(() => {
    if (forceRenderKey && forceRenderKey !== 0) {
      fetchDataForCurrentMonth();
    }
  }, [forceRenderKey]);

  const fetchDataForCurrentMonth = async () => {
    try {
      const currentMonth = new Date().getMonth() + 1;
      const res = await axiosClient.get("/order/get", {
        params: {
          dateFilter: currentMonth.toString(),
        },
      });
      setProduct(res.data.checkProduct);
      setVoucher(res.data.checkVoucher);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error as needed
    }
  };

  const handleFilterChange = async (value) => {
    try {
      let dateFilter;
			if (value === 'Filter By') {
				const currentMonth = new Date().getMonth() + 1;
				dateFilter = currentMonth.toString();
			} else {
				dateFilter = value;
			}

      const res = await axiosClient.get("/order/get", {
        params: {
          dateFilter,
        },
      });

      setProduct(res.data.checkProduct || []);
      setVoucher(res.data.checkVoucher || []);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error as needed
    }
  };


  useEffect(() => {
		handleFilterChange('Filter By');
	}, []);

  // const displayHowToRedeem = (val) => {
  //   if (val === "TNG") {
  //     setRedeemTNG(true);
  //   } else if (val === "GRAB") {
  //     setRedeemGrab(true);
  //   } else {
  //     return null;
  //   }
  // };

  // const displayTNC = (val) => {
  //   if (val === "TNG") {
  //     setTermsTNG(true);
  //   } else if (val === "GRAB") {
  //     setTermsGrab(true);
  //   } else {
  //     return null;
  //   }
  // };

  // const Rewards = () => {
  //   const navigate = useNavigate();
  //   const [redeemTNG, setRedeemTNG] = useState(false);
  //   const [redeemGrab, setRedeemGrab] = useState(false);
  //   const [termsTNG, setTermsTNG] = useState(false);
  //   const [termsGrab, setTermsGrab] = useState(false);
  //   const localtoken = localStorage.getItem("user-token");
  //   const { voucherList, foundAllRatings } = useSelector(
  //     (state) => state.userDetails
  //   );
  //   const { displayOrderedItems, displayItems, displayVouchers } = useSelector(
  //     (state) => state.user
  //   );
  //   const dispatch = useDispatch();
  //   const [value, setValue] = useState("overview");
  //   const [clickedVoucherIndex, setClickedVoucherIndex] = useState(null);
  //   const [rating, setRating] = useState("");
  //   const [productRating, setProductRating] = useState(false);
  //   const [review, setReview] = useState("");
  //   const [errorMsg, setErrorMsg] = useState(false);
  //   const [successMsg, setSuccessMsg] = useState(false);
  //   const [expandedItems, setExpandedItems] = useState([]);
  //   const [redeem, setRedeem] = useState(null);
  //   const [showBarcode, setShowBarcode] = useState(false);
  //   const [product, setProduct] = useState();
  //   const [barcodeValue, setBarcodeValue] = useState();
  //   const [barcodeName, setBarcodeName] = useState("");
  //   const [startCountdown, setStartCountdown] = useState(false);
  //   const [trackingId, setTrackingId] = useState();

  //   const items = [
  //     {
  //       key: "1",
  //       label: "Overview",
  //       value: "overview",
  //     },
  //     {
  //       key: "2",
  //       label: "Last Month",
  //       value: "last_month",
  //     },
  //     {
  //       key: "3",
  //       label: "Last 3 Months",
  //       value: "last_three_months",
  //     },
  //     {
  //       key: "4",
  //       label: "Last 6 Months",
  //       value: "last_six_months",
  //     },
  //   ];

  //   useEffect(() => {
  //     dispatch(checkUserSession({ token: localtoken }));
  //   }, []);

  //   useEffect(() => {
  //     dispatch(getReward({ value }));
  //   }, []);

  //   useEffect(() => {
  //     dispatch(getPointShop());
  //   }, [dispatch]);

  //   useEffect(() => {
  //     dispatch(fetchRatingReview());
  //   }, [dispatch]);

  //   const displayHowToRedeem = (val) => {
  //     if (val === "TNG") {
  //       setRedeemTNG(true);
  //     } else if (val === "GRAB") {
  //       setRedeemGrab(true);
  //     } else {
  //       return null;
  //     }
  //   };

  //   const displayTNC = (val) => {
  //     if (val === "TNG") {
  //       setTermsTNG(true);
  //     } else if (val === "GRAB") {
  //       setTermsGrab(true);
  //     } else {
  //       return null;
  //     }
  //   };

  //   const handleFilterChange = (value) => {
  //     dispatch(getReward({ value }));
  //   };
  //   const handleRatingChange = (value) => {
  //     // console.log("rating", value)
  //     setRating(value);
  //   };

  //   const handleUserReview = (e) => {
  //     setReview(e.target.value);
  //     // console.log("review", e.target.value);
  //   };

  //   const handleReviewRating = (item) => {
  //     const getAllRatings = foundAllRatings.some(
  //       (data) =>
  //         data.productId === item.productId && data.trackingId === trackingId
  //     );

  //     if (!rating) {
  //       setErrorMsg("Please leave the rating of the product!");
  //       setSuccessMsg(false);
  //       setTimeout(() => {
  //         setErrorMsg(false);
  //       }, 1000);
  //     } else if (!review) {
  //       setErrorMsg("Please leave the review of the product!");
  //       setSuccessMsg(false);
  //       setTimeout(() => {
  //         setErrorMsg(false);
  //       }, 1000);
  //     } else if (getAllRatings) {
  //       setErrorMsg("You have submitted rating review for this item!");
  //     } else {
  //       dispatch(
  //         createRatingReview({
  //           review: review,
  //           rating: rating,
  //           productId: item.productId,
  //           trackingId: trackingId,
  //         })
  //       );
  //       setProductRating(false);
  //     }
  //   };

  const toggleDropdown = (index) => {
    if (expandedItems.includes(index)) {
      setExpandedItems(
        expandedItems.filter((itemIndex) => itemIndex !== index)
      );
    } else {
      setExpandedItems([...expandedItems, index]);
    }
  };

  // const voucherClick = async (ele, value) => {
  //   message.success("Copied to clipboard!");
  //   if (clickedVoucherIndex !== index) {
  //   	navigator.clipboard.writeText(value && value.rewardInstance.value);
  //   	try {
  //   		const res = await axiosClient.post('/voucher/check', {
  //   			// isUsed: true,
  //   			// voucher: value,
  //         id: ele.rewardInstanceId,
  //         value: ele.rewardInstance.value,
  //   		});
  //   		if (res) {
  //   			setClickedVoucherIndex(index);
  //   message.success('Copied to clipboard!');
  //   		} else {
  //   			message.error('Code already redeemed!');
  //   		}
  //   	} catch (error) {
  //   		console.error('Error:', error);
  //   	}
  //   } else {
  //   	message.warning('Code already used!');
  //   }
  // };

  const voucherClick = async (ele) => {
    try {
      const res = await axiosClient.post("/voucher/check", {
        id: ele.rewardInstanceId,
        value: ele.rewardInstance.value,
      });
      if (res && res.data) {
        message.success(
          res.data.message ? res.data.message : "Copied to clipboard!"
        );
        navigator.clipboard.writeText(ele.rewardInstance.value);
      } else {
        console.info("error", res.message);
      }
    } catch (e) {
      console.error("error", e);
    }
  };

  const getVoucherType = (ele) => {
    if (["ORDER", "CAMPAIGN"].includes(ele.sourceType)) {
      let from = "From: ";
      from += ele.sourceType === "ORDER" ? "Kik Shop" : "Game";

      return from;
    }
    return "";
  };

  return (
    <div>
      <div>
        <MainHeader />
      </div>

      <div className="ml-2 mr-2" style={{ height: "100vh" }}>
        <div
          className="mt-5"
          style={{
            justifyContent: "space-evenly",
            display: "flex",
            gap: "8px",
          }}
        >
          <Button
            className="btn-color"
            style={{
              backgroundColor: "#C2C2C2",
              color: "white",
            }}
            onClick={() => {
              navigate("/kikshop");
            }}
          >
            Kik Shop
          </Button>

          <Button
            className="btn-color"
            style={{
              backgroundColor: "#274193",
              color: "white",
            }}
            onClick={() => navigate("/rewards")}
          >
            My Rewards
          </Button>
        </div>

        <div style={{ paddingBottom: "120px" }}>
          <div
            className="mt-2"
            style={{ display: "flex", justifyContent: "right" }}
          >
            <Select
              defaultValue="Filter By"
              className="select-tag text-center"
              onChange={handleFilterChange}
              popupClassName="select-dropdown"
              textalign="center"
            >
              {items.map((item) => (
                <Select.Option key={item.key} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div>
            {/* <div
							style={{
								position: 'relative',
								bottom: '60px',
								left: '0',
								width: '90%',
							}}
						>
							<Whatsapp />
						</div> */}
            <div style={{ alignItems: "center" }}>
              {product && product.length ? (
                <h2
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Physical Rewards
                </h2>
              ) : null}
              <Collapse
                defaultActiveKey={[""]}
                bordered={false}
                expandIcon={({ isActive }) =>
                isActive ? (
                  <UpOutlined />
                ) : (
                  <DownOutlined />
                )
              }
                style={{
                  backgroundColor: "white",
                  boxShadow: "0px 1px 16px 0px rgba(0, 0, 0, 0.10)",
                  color: "black",
                }}
                collapsible="header"
              >
                {product?.length &&
                  // <div>
                  product.map((order, index) => {
                    return (
                      <Panel
                        style={{
                          borderRadius: "10px",
                          background: "white",
                          boxShadow: "0px 1px 16px 0px rgba(0, 0, 0, 0.10)",
                          marginTop: "10px",
                          color: "black",
                        }}
                        key={`physical_${index}`}
                        header={
                          <div key={`physical_${index}`}>
                            <div
                              className="delivery-container"
                              onClick={() => toggleDropdown(index)}
                            >
                              {React.Children.toArray(
                                order.orderItem.map((item) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between", // Distribute items evenly
                                      alignItems: "center", // Center items vertically
                                      gap: "30px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <img
                                      // src={item.shopItem.reward.imageUrl}
                                      src={noImg}
                                      alt=""
                                      style={{
                                        width: "20%",
                                        objectFit: "contain",
                                      }}
                                    />
                                    <div
                                      // onClick={() => {
                                      //   if (item?.shopItem?.id) {
                                      //     navigate(
                                      //       "/rewards/items/" +
                                      //         item?.shopItem?.id
                                      //     );
                                      //   }
                                      // }}
                                    >
                                      <p
                                        className="fontSize-12"
                                        style={{
                                          fontWeight: "300",
                                        }}
                                      >
                                        {item.shopItem.name}
                                      </p>
                                      <p
                                        className="fontSize-12"
                                        style={{
                                          fontWeight: "300",
                                          color: "#6B6B6B",
                                        }}
                                      >
                                        Redeemed{" "}
                                        {moment(item.createdAt).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </p>
                                    </div>
                                    <div>{`x${item.qty}`}</div>
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                        }
                      >
                        {React.Children.toArray(
                          expandedItems.includes(index) && (
                            <div>
                              {React.Children.toArray(
                                order.orderItem.map((item) => {
                                  const hasReviewed =
                                    item?.shopItem?.itemReview?.find(
                                      (review) =>
                                        review.comUserId === order?.comUserId &&
                                        review?.orderId === order?.id
                                    );

                                  return (
                                    <div>
                                      <hr
                                        style={{
                                          height: "2px",
                                          backgroundColor: "#F2F2F2",
                                          border: "none",
                                        }}
                                      />

                                      {/* <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'left',
                                            paddingBottom: '10px',
                                            opacity:
                                              order?.isShipped && !hasReviewed ? 1 : '0.5',
                                          }}
                                          onClick={() => {
                                            if (
                                              order?.isShipped &&
                                              !hasReviewed
                                            ) {
                                              setProductRating(true);
                                              // setReviewProduct({
                                              //   ...item.shopItem,
                                              //   orderId: order.id,
                                              // });
                                            }
                                          }}
                                        >
                                          <Rate
                                            value={hasReviewed?.rating || 0}
                                            className="custom-yellow-border-rate"
                                          />
                                        </div> */}
                                    </div>
                                  );
                                })
                              )}
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "left",
                                    gap: "10px",
                                  }}
                                >
                                  <img
                                    src={deliveryIcon}
                                    alt=""
                                    style={{
                                      width: "auto",
                                      objectFit: "contain",
                                    }}
                                  />
                                  <p
                                    className="fontSize-16"
                                    style={{ fontWeight: "500" }}
                                  >
                                    Delivery Address
                                  </p>
                                </div>
                                <div>
                                  <p
                                    style={{ fontWeight: "500" }}
                                    className="fontSize-16"
                                  >
                                    {order.address.name || ""}
                                  </p>
                                  <p
                                    className="fontSize-16"
                                    style={{ fontWeight: "300" }}
                                  >
                                    {order.address.phone
                                      ? `+6${order.address.phone}`
                                      : null}
                                  </p>
                                  <p
                                    className="fontSize-12"
                                    style={{
                                      fontWeight: "400",
                                      color: "#4A4A4A",
                                    }}
                                  >
                                    {`${order.address.line1 || ""}, ${
                                      order.address.line2 || ""
                                    }, ${order.address.postcode || ""} ${
                                      order.address.city || ""
                                    }`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "left",
                                    gap: "10px",
                                  }}
                                  className="mt-2"
                                >
                                  <img
                                    src={trackOrderIcon}
                                    alt=""
                                    style={{
                                      width: "auto",
                                      objectFit: "contain",
                                    }}
                                  />
                                  <p
                                    className="fontSize-16"
                                    style={{ fontWeight: "500" }}
                                  >
                                    Track Your Order
                                  </p>
                                </div>
                                <p
                                  className="fontSize-12"
                                  style={{ fontWeight: "500" }}
                                >
                                  Delivery Status: {order.status}
                                </p>
                                {/* <p
                                    className="fontSize-14"
                                    style={{ fontWeight: '500' }}
                                  >
                                    Remark:{' '}
                                    {order.remark
                                      ? order.remark
                                      : 'In processing'}
                                  </p> */}
                                {/* <p className="fontSize-12">
                                    Call our careline for delivery status
                                    <br />
                                    +60 11-5412 5382 <br />
                                    Monday - Friday (Except Public Holiday)
                                    <br />
                                    9am - 5pm
                                  </p> */}
                              </div>
                            </div>
                          )
                        )}
                      </Panel>
                    );
                  })}
              </Collapse>
            </div>

            <div className="mt-2">
              {voucher && voucher.length ? (
                <h2
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    textAlign: "left",
                  }}
                >
                  Voucher Rewards
                </h2>
              ) : null}
              {voucher && voucher.length
                ? voucher.map((ele, index) => {
                    return (
                      <div key={index}>
                        <div className="mt-2" key={index}>
                          <div
                            style={{
                              padding: "2vh 1vw",
                              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                              borderRadius: "10px",
                            }}
                          >
                            <div
                              className="d-flex"
                              style={{
                                justifyContent: "center",
                              }}
                            >
                              <div className="my-reward-card">
                                <div
                                  className="d-flex"
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "space-evenly",
                                  }}
                                >
                                  <img
                                    style={{ width: "50px" }}
                                    className="fit-contain"
                                    src={
                                      ele.rewardInstance.reward.imageUrl ||
                                      noImg
                                    }
                                    alt=""
                                  />
                                  <div
                                    style={{ width: "60%" }}
                                    className="ml-2"
                                  >
                                    <h3
                                      style={{
                                        fontWeight: "450",
                                        textAlign: "left",
                                        fontFamily: "Brandon Grotesque, Arial",
                                        fontSize: "18px",
                                      }}
                                    >
                                      {ele.rewardInstance.reward.name}{" "}
                                    </h3>
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        textAlign: "left",
                                      }}
                                      className="t-black"
                                    >
                                      {ele.rewardInstance.reward.description}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="d-flex"
                                  style={{
                                    justifyContent: "center",
                                    gap: "40px",
                                  }}
                                >
                                  <div
                                    style={{
                                      textAlign: "left",
                                      position: "relative",
                                      bottom: "6px",
                                    }}
                                  >
                                    <p
                                      className="fontSize-12"
                                      style={{
                                        position: "relative",
                                        top: "35px",
                                        color: "#6B6B6B",
                                        textTransform: "capitalize",
                                        marginBottom: "2px",
                                      }}
                                    >
                                      {getVoucherType(ele)}
                                    </p>
                                    <p
                                      className="fontSize-12"
                                      style={{
                                        position: "relative",
                                        top: "35px",
                                        color: "#6B6B6B",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      Voucher issued:
                                      <br />
                                      {formatRelative(
                                        new Date(ele.createdAt),
                                        new Date()
                                      )}
                                    </p>
                                  </div>
                                  <div className="mt-3">
                                    <div
                                      onClick={() => {
                                        voucherClick(ele);
                                      }}
                                      className="px-1 py-1"
                                      style={{
                                        backgroundColor: "#274193",
                                        borderRadius: "5px",
                                        color: "white",
                                        height: "30px",
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                        lineHeight: "30px",
                                        width: "120px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <CopyToClipboardLib
                                          text={ele.rewardInstance.value}
                                        >
                                          <p
                                            style={{
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              fontSize: "12px",
                                              marginRight: "8px",
                                            }}
                                          >
                                            {ele.rewardInstance.value}
                                          </p>
                                        </CopyToClipboardLib>
                                        <CopyOutlined
                                          style={{
                                            color: "white",
                                            fontSize: "18px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {/* <p
                                      style={{
                                        fontSize: "12px",
                                        color: "#929292",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                      }}
                                      onClick={() =>
                                        displayTNC(
                                          ele.rewardInstance.reward.voucherType
                                          "TNG"
                                        )
                                      }
                                    >
                                      Terms & Conditions
                                    </p> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                    // }
                  })
                : // <p
                  //   className="text-center font-weight-600 mt-5"
                  //   style={{ fontSize: "18px" }}
                  // >
                  //   User don't have any rewards yet
                  // </p>
                  null}
            </div>
          </div>
        </div>
      </div>
      <UserFooter type={"rewardsPage"} />
    </div>
  );
};
export default Rewards;
