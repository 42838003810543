import "../../css/Login.css";
import { Button, Form, Input, Modal } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import axiosClient from "../../utils/axiosClient";
// import checkInputFormat from "../../utils/misc/validation";
import rejected from "../../images/rejected.png";
import { useState } from "react";
// import Whatsapp from '../Whatsapp';

const LoginForm = ({ formConfig }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  // const [disabled, setDisabled] = useState(false);
  const [success, setSuccess] = useState(false);
  const [exist, setExist] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  // const storeId = location.state?.storeId;
  const referId = location.state?.referId;
  const regexPhoneNum = /^(1[0-9]{8,9})$/;

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const sendData = {
        phone: "60" + values.phone,
      };
      const res = await axiosClient.post("/auth/check-user", sendData);
      setLoading(false);

      if (res) {
        const data = {
          ...values,
          identity: res.data?.identity,
          referId,
        };
        navigate(`/verify-otp${window.location.search}`, { state: data });
      }
    } catch (error) {
      setErrorModal(true);
      setLoading(false);
      setExist(true);
      setError(error.response.data.error);
    }
  };

  return (
    <div>
      <Form
        form={form}
        name="login-form"
        id="login-form"
        onFinish={handleSubmit}
        disabled={loading}
        aria-label="form"
        data-testid="login-form"
        autoComplete="off"
        style={{ textAlign: "center",  color: "black" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "left",
          }}
        >
          <div
            style={{
              borderRadius: "10px",
              border: "1px solid grey",
              backgroundColor: "white",
              width: "14%",
              height: "35px",
              fontSize: "12px",
              color: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "10%",
            }}
          >
            <span>+60</span>
          </div>
          <Form.Item
            name="phone"
            validateDebounce={500}
            hasFeedback
            className="auth-text-color"
            style={{ fontSize: "12px", width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please enter your phone number",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && value?.length > 0) {
                    if (regexPhoneNum.test(value)) {
                      return Promise.resolve(true);
                    }
                    return Promise.reject(
                      new Error("Invalid phone number")
                    );
                  }
                  return Promise.resolve(true);
                },
              }),
            ]}
          >
            <Input
              type="tel"
              name="phone"
              style={{
                fontFamily: "Poppins",

                borderRadius: "10px",
                border: "1px solid grey",
                backgroundColor: "white",
                width: "83%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                height: "35px",
                position: "relative",
                right: "5%",
                color: "black",
              }}
              placeholder="Your Phone Number"
              />
          </Form.Item>
        </div>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: "#264c96",
              width: "80%",
              margin: "auto",
              border: "none",
              fontSize: "20px",
              fontWeight:'bolder',
              textDecoration: 'none',
              height: "40px",

              borderRadius: "10px",

              fontFamily:"Poppins",
            }}
          >
            LOGIN
          </Button>
        </Form.Item>
      </Form>
      <div>
        <Modal
          open={success}
          centered
          header={null}
          footer={null}
          closable={true}
        >
          <div className="submit-login-success-modal-container">
            <img
              alt=""
              className="submit-success-icon"
              style={{ width: "15vw" }}
            ></img>
            <div className="submit-login-modal-header">Success!</div>
            <div className="submit-login-success-modal-font">
              New one-time PIN sent. Please check your phone for the code.
            </div>
            <Button  onClick={() => navigate("/verify-otp")}>DONE</Button>
          </div>
        </Modal>
      </div>

      <div>
        <Modal
          open={errorModal}
          centered
          footer={null}
          width={300}
          closable={true}
          onCancel={() => setErrorModal(false)}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={rejected} alt="" style={{ width: "20%" }}></img>
          </div>
          <br />
          <div
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              textAlign: "center",
            }}
          >
            Unable to send OTP
            <p className="text-center">please try again</p>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default LoginForm;
