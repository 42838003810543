import "../../css/Login.css";
import { Button, Modal, Spin,message } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react18-input-otp";
// import Whatsapp from "../../components/Whatsapp";
// import axios from "axios";
import axiosClient from "../../utils/axiosClient";
// import check from "../../images/checkmark1.png";
import logo from "../../images/Jomkik logo.svg";
import rejected from "../../images/rejected.png";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useTranslation } from "react-i18next";
import brandMocost from "../../images/loginMocost.svg";
import loginBackground from "../../images/loginBackground.svg";

const inputStyle = {
  border: "none",
  width: "2.5rem",
  height: "3rem",
  textAlign: "center",
  backgroundColor: "white",
  borderRadius: "10px",
  boxShadow: "none",
  outline: "none",
  color: "black",
  fontSize: "20px",
  fontWeight: "bold",
  borderBottom: "2px solid black",
};

const containerStyle = {
  textAlign: "center",
  margin: "auto",
  justifyContent: "center",
  marginBottom: "5%",
  borderBottom: "black",
};

const LoginPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(60);
  const { t } = useTranslation();
  const [errorModal, setErrorModal] = useState(false);
  const [, setTempOtp] = useLocalStorage("tempOtp");
  // const [, setUserToken] = useLocalStorage('user-token');

  // const storeId = location.state?.storeId;
  // const referId = location.state?.referId;

  useEffect(() => {
    if (timer > 0) {
      const count = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearTimeout(count);
    }
  }, [timer]);

  const showModal = async () => {
    setLoading(true);

    try {
      const IDENTITY = location.state?.identity;
      const res = await axiosClient.post("/auth/verify-token", {
        token: otp,
        tokenAction: IDENTITY,
        tokenType: "PHONE",
        recipient: "60" + location.state?.phone,
        degenerate: IDENTITY === "REGISTER" ? false : true,
      });

      if (res) {
        setMsg("Your phone number has been successfully verified.");
        setIsModalOpen(true);
        if (IDENTITY === "LOGIN") {
          localStorage.setItem("user-token", res.data.token);
        } else if (IDENTITY === "REGISTER") {
          setTempOtp(otp);
          setTimeout(() => {
            navigate("/register"+ window.location.search, { state: location.state });
          }, 3000);
        }
      }
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
      console.error(error.response.data.message);
    }
  };

  const resendOtp = async () => {
    if (timer !== 0) {
      return;
    }
    setOtp("");

    try {
      const IDENTITY = location.state?.identity;
      const res = await axiosClient.post("/auth/token", {
        recipient: "60"+location?.state.phone,
        tokenAction: IDENTITY,
        tokenType: "PHONE",
      });

      if (res) {
        message.success("new otp code sent successfully");
        setTimer(60);
      }
    } catch (error) {
      message.error(error?.response.data.message || "Error");
    }
  };

  const checkRegister = async () => {
    if (location.state.identity === "REGISTER") {
      navigate("/register"+window.location.search, { state: location.state });
    } else {
      navigate("/home"+window.location.search);
    }
  };

  return (
    <div className="text-center" style={{
      position: 'relative',

      backgroundImage: `url(${loginBackground})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',

      minHeight: '100vh',
    }}>
      <div className="logo-position">
        {/* Jomkik logo */}
        <img
          src={logo}
          alt="jomkik"
		  style={{width:'35%', marginBottom:'4%'}}
        />
        {/* <Whatsapp /> */}
      </div>
      <img src={brandMocost} alt="pic" style={{width:'50%'}} />
      <div>
        {/* <div className="card" style={{ textAlign: "center" }}> */}
        <div className="text-center">
          <h1
           style={{
              fontFamily: 'Poppins',
              textTransform: 'uppercase',
              fontSize: '25px',
            color:'#264c96'
          }}
          >Verification Code</h1>
          <p className="mt-1 text-center"
          
            style={{
            color: "black",
          }}>
            Please enter the code that was
            sent to <br/><b>+60{location?.state?.phone}</b>
          </p>

          <div className="login-input-container"
            style={{
            marginTop: "1%",
            }}>
            <OtpInput
              value={otp}
              numInputs={4}
              onChange={(e) => setOtp(e)}
              isInputNum={true}
              separator={<span> &nbsp;&nbsp;&nbsp;&nbsp; </span>}
              containerStyle={containerStyle}
              inputStyle={inputStyle}
            />
          </div>

          {!isModalOpen ? 
          (
            <>
              {" "}
              <div className="verify-container">
                {error ? (
                  <div
                    style={{
                      textAlign: "center",
                      color: "red",
                      fontSize: "17px",
                    }}
                  >
                    {error}
                  </div>
                ) : (
                  ""
                )}
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    color: "black",
                  }}
                >
                  {timer}s
                </div>
                <br />
                <div className="verify-btn">
                  <Button
                    loading={loading}
                    onClick={showModal}
                    type="primary"
                    style={{
                      backgroundColor: "#274193",
                      textDecoration: "none",
                      width: "220px",
                      color: "white",
                      fontWeight: "600",
                      height:'40px'
                    }}
                  >
                    CONFIRM
                  </Button>
                </div>
              </div>
              <div>
                <p
                  className="text-center mt-1"
                  onClick={resendOtp}
                  style={{
                    opacity: timer === 0 ? "1" : "0.5",
                    fontSize: "14px",
                    fontWeight: "700",
                    cursor: "pointer",
                    paddingBottom: '5%',
                    color: 'black'
                  }}
                >
                  Resend Code
                </p>
              </div>
            </>
          ) : (
            <Spin className="verify-loading-btn pos-relative" />
          )}
          <div className="text-deco-none">
            <Modal
              open={isModalOpen}
              footer={null}
              closable={false}
              centered
              width={330}
            >
              <div className="text-center">
                <p className="text-center mt-1" style={{
                  fontSize:'15px',
                  fontWeight:'500',
                }}>{msg}</p>
                <Button
                  type="primary"
                  className="mt-2 text-deco-none "
                  style={{
                    color: "white",
                    width: "80%",
                    background: "#274193",
                    fontWeight: "600",
                    borderRadius: "5px",
                  }}
                  onClick={() => checkRegister()}
                  // onClick = {()=> navigate("/home")}
                >
                  Done
                </Button>
              </div>
            </Modal>

            <Modal
              open={errorModal}
              centered
              footer={null}
              closable={true}
              width={300}
              onCancel={() => setErrorModal(false)}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={rejected} alt="" style={{ width: "20%" }}></img>
              </div>
              <br />
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  textAlign: "center",
                }}
              >
                {error}
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
