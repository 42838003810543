import "../css/Home.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosClient from "../utils/axiosClient";
import noImage from "../images/imageUnavailable.jpeg";
import { Button } from "antd";
import spinWheel from "../images/SpinWheel.png";
const ScrollableImages = () => {
  const navigate = useNavigate();
  const [displayItem, setDisplayItem] = useState(["JomKiK Spin And Win"]);

  useEffect(() => {
    const fetchGameDetails = async () => {
      try {
        const res = await axiosClient.get("/campaigns", {
          params: { type: "GAMES" },
        });
        if (res && res.data) {
          setDisplayItem(res.data);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchGameDetails();
  }, []);

  return (
    // <div className="mt-2 scroll-img" style={{ display: "flex" }}>
    <div className="mt-2">
      {displayItem && displayItem?.length ? (
        displayItem.map(({ id, name, imageUrl }, index) => {
          const handleClick = () => {
            if (id) {
              navigate("/game/" + id);
            } else {
            }
          };
          return (
            <div className="text-center" key={index} onClick={handleClick}>
              <div
                style={{
                  borderRadius: "20px",
                  border: "1px solid #D9D9D9",
                  width: "100%",
                  height: "100%",
                  paddingBottom: "5%",
                }}
              >
                <img
                  src={
                    id === "d4077e42-2114-41f2-9280-de41ece1b73c"
                      ? spinWheel
                      : imageUrl
                      ? imageUrl
                      : noImage
                  }
                  alt=""
                  style={{ width: "30%", paddingTop: "5%" }}
                />
                <div style={{ textAlign: "center" }}>
                  <p
                    className="fontSize-18"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      // marginLeft: "10%",
                      // marginRight: "10%",
                      textAlign: "center",
                      fontWeight: "600",
                    }}
                  >
                    {name}
                  </p>
                </div>
                <Button
                  className="fontSize-18 mt-2"
                  style={{
                    color: "white",
                    backgroundColor: "#274193",
                    fontWeight: "700",
                    width: "50%",
                  }}
                  onClick={handleClick}
                >
                  PLAY
                </Button>
              </div>
            </div>
          );
        })
      ) : (
        <div>
          <p className="text-center">Stay Tuned for more exciting rewards!</p>
        </div>
      )}
    </div>
  );
};

export default ScrollableImages;
