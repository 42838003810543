import React, { useState, useEffect } from "react";
import { Button, Modal, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import closeBtn from "../../images/CloseBtn.svg";
import { InfoCircleOutlined, FormOutlined } from "@ant-design/icons";
import rejectedImg from "../../images/rejected.png";
import SubHeader from "../../components/SubHeader";
import "../../css/Cart.css";
// import Whatsapp from '../../components/Whatsapp';
import axiosClient from "../../utils/axiosClient";

const CartSummary = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [succesMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const getAddress = location.state?.addr || "";
  const getAddressId = location?.state?.addr?.id || "";

  const [totalPoint, setTotalPoint] = useState(0);
  const [isAbleToCheckout, setIsAbleToCheckout] = useState(false);
  const [cartsSummaryErrors, setCartsSummaryErrors] = useState([]);
  const [selectedCarts, setSelectedCarts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [forceRenderKey, setForceRenderKey] = useState(0);
  const [rewardType, setRewardType] = useState();

  const fetchUserCartsSummary = () => {
    axiosClient
      .get("/carts/summary")
      .then((result) => {
        if (result?.data?.data) {
          (result.data.data.isAbleToCheckout !== undefined ||
            result.data.data.isAbleToCheckout !== null) &&
            setIsAbleToCheckout(result.data.data.isAbleToCheckout);

          setTotalPoint(result.data.data.totalPoints || 0);
          setRewardType(result.data.data.rewardType);

          Array.isArray(result.data.data.cartsSummaryErrors) &&
            setCartsSummaryErrors(result.data.data.cartsSummaryErrors);

          Array.isArray(result.data.data.cartItems) &&
            setSelectedCarts(
              result.data.data.cartItems.filter((_c) => _c.isSelected)
            );
        } else {
          console.error("result: ", result);
        }
      })
      .catch((err) => {
        console.error("fetchUserCartsSummary: ", err);
        message.error(err?.message || "Error occur on fetching carts");
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    !isLoading && fetchUserCartsSummary();
  }, [isLoading]);

  const handleConfirm = () => {
    setIsLoading(true);
    axiosClient
      .post("/orders", {
        currency: "WALLET",
        total: totalPoint,
        type: "WALLET_SHOP",
        ...(getAddressId &&
          rewardType === "PRODUCT" && { addressId: getAddressId }),
      })
      .then((result) => {
        if (result?.data?.data) {
        //   console.log("result: ", result?.data?.data);
          setSuccessMsg(true);
          navigate("/cartSuccess");
        } else {
          console.error("result: ", result);
        }
      })
      .catch((err) => {
        console.error("fetchUserCartsSummary: ", err);
        setErrorMsg(err?.message || "Error occur on creating order");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDeleteItem = (_cartId) => {
    if (window.confirm("Remove item from cart?")) {
      setIsLoading(true);

      axiosClient
        .delete("/carts/" + _cartId)
        .then((result) => {
          setSelectedCarts((prev) => prev.filter((_s) => _s.id !== _cartId));
          message.success("Succesfully removed");
        })
        .catch((err) => {
          console.error("handleDeleteCart: ", err);
          message.error(err?.message || "Error occur on removing cart");
        })
        .finally(() => {
          setIsLoading(false);
          setForceRenderKey((prev) => prev + 1);
        });
    }
  };

  return (
    <div style={{ opacity: isLoading ? "0.5" : "1" }}>
      <SubHeader setPreviousPage={-1} forceRenderKey={forceRenderKey} />
      <div className="mt-2">
        <p
          style={{ fontSize: "25px", fontWeight: "500", textAlign: "left" }}
          className="ml-2"
        >
          Order Summary
        </p>
        <p
          style={{ fontWeight: "400", textAlign: "left" }}
          className="ml-2 mt-1 fontSize-16"
        >
          Review your order.
        </p>
      </div>

      <div className="ml-2 mr-2 mt-2" style={{ paddingBottom: "30px" }}>
        {selectedCarts.length > 0 ? (
          <>
            {/* CART ITEMS */}
            {selectedCarts.map((_cartItem, index) => {
              return (
                <div className="mt-3">
                  <div
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#FFF",
                      width: "auto",
                      boxShadow: "0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
                    }}
                    key={index}
                  >
                    <div style={{ textAlign: "right" }}>
                      <img
                        src={closeBtn}
                        alt=""
                        style={{
                          borderRadius: "50px",
                          background: "rgba(236, 236, 236, 0.80)",
                          width: "6%",
                        }}
                        onClick={() => handleDeleteItem(_cartItem.id)}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <div
                        style={{
                          background: "#D7D7D7",
                          display: "flex",
                          justifyContent: "center",
                          width: "60px",
                          height: "60px",
                          alignItems: "center",
                          padding: "8px",
                        }}
                        className="ml-1"
                      >
                        <img
                          src={_cartItem?.shopItem?.reward?.imageUrl}
                          alt=""
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div
                        style={{
                          width: "45%",
                          alignItems: "center",
                        }}
                        className="ml-2"
                      >
                        <p style={{ fontWeight: "600" }}>
                          {_cartItem?.shopItem?.name}
                        </p>
                        <p>
                          <b>{_cartItem?.shopItem?.priceValue}</b> KiKs
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "20%",
                        }}
                        className="mr-1"
                      >
                        <p className="fontSize-14">
                          Quantity: {_cartItem?.qty}
                        </p>
                      </div>
                    </div>
                    <div style={{ flex: "1", padding: "10px 20px" }}>
                      {_cartItem?.cartError && (
                        <p style={{ color: "red" }}>{_cartItem?.cartError}</p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

            {/* FOOTER */}
            <div>
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                className="mt-3"
              >
                <div>
                  <p style={{ fontSize: "20px", fontWeight: "300" }}>Total: </p>
                </div>
                <p style={{ fontSize: "20px", fontWeight: "700" }}>
                  {totalPoint} KiKs
                </p>
              </div>

              {/* { ((items.categoryName === "voucherCategory" && items.categoryName === "itemCategory")|| items.categoryName === "itemCategory") &&  */}
              {getAddress && (
                <div>
                  {/* { getDeliveryMethod === "pickup" ? <p style={{ fontSize:'18px', fontWeight:'500', marginTop:'30px' }}>Pick-up outlet</p> : getDeliveryMethod === "delivery" ? <p style={{ fontSize:'18px', fontWeight:'500', marginTop:'30px' }}>Delivery</p> : null } */}
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      marginTop: "30px",
                    }}
                  >
                    Delivery Address
                  </p>

                  {/* { getDeliveryMethod === "pickup" || getDeliveryMethod === "delivery" ?  */}
                  <div
                    style={{
                      boxShadow: "0px 0px 23px 0px rgba(0, 0, 0, 0.10)",
                      borderRadius: "10px",
                      height: "138px",
                      border: "2px solid #274193",
                      background: "#FFF",
                      position: "relative",
                    }}
                    className="mt-1"
                  >
                    <div style={{ width: "320px", justifyContent: "left" }}>
                      <div className="pickup-container">
                        <div style={{ textAlign: "left" }}>
                          <p className="fontSize-18">{getAddress?.name}</p>
                          <p
                            className="fontSize-16"
                            style={{ color: "#64656A", margin: "2px 0" }}
                          >
                            {getAddress?.phone}
                          </p>
                          <p
                            className="fontSize-16"
                            style={{ color: "#64656A", width: "100%" }}
                            key={getAddress?.id}
                          >
                            {getAddress?.line1} {getAddress?.line2},<br />
                            {getAddress?.postcode}, {getAddress?.city}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* 
                                { getDeliveryMethod === "pickup" ?   
                                    <div className="change-outlet-btn" style={{display:'flex', justifyContent:'center'}} onClick = {() => navigate("/storeLocator", { state: { deliveryMethod:'pickup' }})}>
                                        <FormOutlined />
                                        <span 
                                            className='fontSize-12' 
                                            style={{marginLeft:'5px', fontWeight:'420'}}
                                        >Change Outlet</span>
                                    </div>
                                :
                                    getDeliveryMethod === "delivery" ?  */}

                    <div
                      className="change-outlet-btn"
                      style={{ display: "flex", justifyContent: "center" }}
                      onClick={() => navigate("/deliveryAddress")}
                    >
                      <FormOutlined />
                      <span
                        className="fontSize-12"
                        style={{ marginLeft: "5px", fontWeight: "420" }}
                      >
                        Edit Delivery Address
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {/* <div
									style={{
										position: 'relative',
										top: '20px',
										left: '0',
										width: '90%',
									}}
								>
									<Whatsapp />
								</div> */}

              {/* SHOW CARTS ERROR IF ANY */}
              {cartsSummaryErrors.length > 0 &&
                React.Children.toArray(
                  cartsSummaryErrors.map((_err) => (
                    <p
                      style={{
                        color: "red",
                        textAlign: "center",
                        marginTop: "10px",
                      }}
                    >
                      {_err}
                    </p>
                  ))
                )}

              <Button
                disabled={!isAbleToCheckout}
                style={{
                  opacity: !isAbleToCheckout ? "0.5" : "1",
                  borderRadius: "5px",
                  background: "#274193",
                  width: "100%",
                  fontSize: "14px",
                  fontWeight: "500",
                  height: "45px",
                  marginBottom: "50px",
                  marginTop: "100px",
                  color: "white",
                }}
                onClick={handleConfirm}
              >
                Confirm
              </Button>
            </div>
          </>
        ) : (
          <div style={{ paddingTop: "120px" }}>
            <InfoCircleOutlined
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "80px",
                color: "darkgray",
              }}
            />
            <h1 className="text-center mt-2" style={{ fontSize: "25px" }}>
              No items found
            </h1>
            <p className="text-center mt-2 ml-3 mr-3 fontSize-16">
              Nothing to checkout from this page.
            </p>
            <div
              className="mt-5"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                style={{
                  backgroundColor: "black",
                  border: "none",
                  color: "white",
                  height: "45px",
                  width: "70%",
                }}
                onClick={() => navigate("/kikshop")}
              >
                Back to Rewards Page
              </Button>
            </div>
          </div>
        )}
      </div>

      <Modal
        open={succesMsg}
        centered
        header={null}
        footer={null}
        // onCancel={() => setSuccessMsg(false)}
        closable={false}
        width={300}
      >
        <div className="text-center">
          {/* <img style={{ width: '20%' }} src={successImg} alt="" className="submit-success-icon"></img> */}
          <div className="submit-receipt-success-header">Success!</div>
          <div className="submit-pending-modal-font mt-1">
            <p className="text-center">
              You have successfully redeemed the rewards!
            </p>
          </div>
          <Button
            className="receipt-success-button mt-1"
            style={{ backgroundColor: "#02BC7D" }}
            onClick={() => navigate("/rewards")}
          >
            Go To Rewards
          </Button>
        </div>
      </Modal>

      <Modal
        open={errorMsg}
        centered
        header={null}
        footer={null}
        // onCancel={() => setSuccessMsg(false)}
        closable={false}
        width={300}
      >
        <div className="text-center">
          <img
            style={{ width: "20%" }}
            src={rejectedImg}
            alt=""
            className="submit-success-icon"
          ></img>
          {/* <div className="submit-receipt-success-header">Error</div> */}
          <div className="submit-pending-modal-font mt-1">
            <p className="text-center t-black">{errorMsg}</p>
          </div>
          <Button
            className="receipt-success-button mt-2"
            style={{
              backgroundColor: "#30E700",
              border: "none",
              width: "100%",
            }}
            onClick={() => setErrorMsg()}
          >
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default CartSummary;
