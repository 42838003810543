import "../../css/Submissions.css";
import { Button, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import // checkUserSession,
// checkVouchers,
// fetchHistory,
"../../reducer/userReducer";
import MainHeader from "../../components/MainHeader";
import UserFooter from "../../components/UserFooter";
// import moment from "moment";
import axiosClient from "../../utils/axiosClient";
// import notAvailableImg from "../../images/imageUnavailable.jpeg";
import { formatRelative } from "date-fns";

const Submissions = () => {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const location = useLocation();
  const [tab, setTab] = useState("Token");
  const [submission, setSubmission] = useState(false);
  const [points, setPoints] = useState(false);
  const [pointAndTokenHistory, setPointAndTokenHistory] = useState([]);
  const [gameHistory, setGameHistory] = useState([]);

  useEffect(() => {
    const checkGameDetails = async () => {
      try {
        const campaignIds = [
          "c22500c9-a468-4435-8f4d-cc149fe502c5",
          "d4077e42-2114-41f2-9280-de41ece1b73c",
        ];

        let combinedSubmissionInfo = [];

        for (const campaignId of campaignIds) {
          const gameRes = await axiosClient.get("/submissions", {
            params: {
              campaignId: campaignId,
            },
          });

          if (
            gameRes &&
            gameRes.data &&
            Array.isArray(gameRes.data.getFinalSubmissionInfo)
          ) {
            combinedSubmissionInfo = combinedSubmissionInfo.concat(
              gameRes.data.getFinalSubmissionInfo
            );
          }
        }
        // Set the state with the combined submission info
        setGameHistory(combinedSubmissionInfo);
      } catch (e) {
        console.error("Error:", e?.response?.data?.message);
      }
    };

    checkGameDetails();

    const checkPointAndTokenHistory = async () => {
      try {
        const res = await axiosClient.get("/points/get");

        if (res && res.data) {
          setPointAndTokenHistory(res.data.data);
        }
      } catch (e) {
        console.error("e", e?.response?.data?.message);
      }
    };
    checkPointAndTokenHistory();
  }, []);

  return (
    <div>
      <div>
        <MainHeader />
      </div>
      <div className="ml-2 mr-2" style={{ paddingBottom: "130px" }}>
        <div className="mt-3">
          <p
            style={{ fontSize: "25px", fontWeight: "500", textAlign: "center" }}
          >
            History
          </p>
        </div>

        <div
          className="mt-2"
          style={{
            justifyContent: "space-around",
            display: "flex",
            gap: "8px",
          }}
        >
          <Button
            style={{
              backgroundColor: tab === "Token" ? "#274193" : "#C2C2C2",
              color: "white",
              border: "none",
              width: "33%",
            }}
            onClick={() => {
              setTab("Token");
              setSubmission(true);
            }}
          >
            Token
          </Button>

          <Button
            style={{
              backgroundColor: tab === "Points" ? "#274193" : "#C2C2C2",
              color: "white",
              border: "none",
              width: "33%",
            }}
            onClick={() => {
              setTab("Points");
              setPoints(true);
            }}
          >
            KiKs
          </Button>

          <Button
            style={{
              backgroundColor: tab === "Game" ? "#274193" : "#C2C2C2",
              color: "white",
              width: "33%",

              border: "none",
            }}
            onClick={() => {
              setTab("Game");
              setSubmission(true);
            }}
          >
            Games
          </Button>
        </div>
        {/* <div
          className="mt-1"
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
          }}
        >
          <Select
            defaultValue="Filter By"
            className="select-tag text-center"
            onChange={handleChange}
            // dropdownClassName="select-dropdown"
            popupClassName="select-dropdown"
          >
            {items.map((item) => (
              <Select.Option key={item.key} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </div> */}
        {tab === "Token" &&
          (pointAndTokenHistory?.length <= 0 ||
          !pointAndTokenHistory.some((ele) => ele.type === "TOKEN") ? (
            <div className="mt-5">
              <h3 className="text-center t-black">No token history yet</h3>
            </div>
          ) : null)}

        {tab === "Token" && pointAndTokenHistory?.length ? (
          tab === "Token" &&
          pointAndTokenHistory.some((ele) => ele.type === "TOKEN") ? (
            pointAndTokenHistory.map((ele, index) => {
              if (ele.type !== "TOKEN") {
                return null;
              }
              return (
                <div key={index}>
                  <div className="mt-2">
                    <div
                      style={{
                        boxShadow: "0px 2px 18px rgba(0, 0, 0, 0.16)",
                        borderRadius: "8px",
                      }}
                    >
                      <div className="text-center" style={{ width: "100%" }}>
                        <div className="submission-history-card d-flex">
                          <div
                            className="d-flex"
                            style={{
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* <div
                              style={{
                                textAlign: "left",
                                width: "30%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                style={{
                                  width: "100px",
                                  height: "90px",
                                  objectFit: "contain",
                                }}
                                src={notAvailableImg}
                                alt=""
                              />
                            </div> */}
                            <div style={{ width: "70%" }} className="ml-1">
                              <h3
                                style={{
                                  fontWeight: "500",
                                  fontFamily: "Brandon Grotesque, Arial",
                                  fontSize: "15px",
                                  textAlign: "left",
                                  color: "#274193",
                                }}
                              >
                                {ele.description}
                              </h3>
                              <p
                                className="t-black fontSize-14"
                                style={{
                                  textAlign: "left",
                                  marginTop: "3%",
                                  textTransform: "capitalize",
                                }}
                              >
                                {formatRelative(
                                  new Date(ele.createdAt),
                                  new Date()
                                )}
                              </p>
                            </div>
                            <div style={{ width: "30%", paddingRight: "3%" }}>
                              {ele.operation === "DEBIT" ? (
                                <p
                                  style={{
                                    fontWeight: "600",
                                    textAlign: "right",
                                    color: "black",
                                  }}
                                >
                                  -{ele.value} Token
                                </p>
                              ) : ele.operation === "CREDIT" ? (
                                <p
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "600",
                                    color: "black",
                                  }}
                                  className="fontSize-16"
                                >
                                  +{ele.value} Token
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="mt-5">
              <h3 className="text-center">No history shown yet</h3>
            </div>
          )
        ) : null}

        {/* {tab === "Points" &&
          (pointAndTokenHistory?.length <= 0 ||
          !pointAndTokenHistory.some((ele) => ele.type === "WALLET") ? (
            <div className="mt-5">
              <h3 className="text-center t-black">No points history yet</h3>
            </div>
          ) : null)} */}

        {tab === "Points" && pointAndTokenHistory?.length ? (
          tab === "Points" &&
          pointAndTokenHistory.some((ele) => ele.type === "WALLET") ? (
            pointAndTokenHistory.map((ele, index) => {
              if (ele.type !== "WALLET") {
                return null;
              }
              return (
                <div key={index}>
                  <div className="mt-2">
                    <div
                      style={{
                        boxShadow: "0px 2px 18px rgba(0, 0, 0, 0.16)",
                        borderRadius: "8px",
                      }}
                    >
                      <div className="text-center" style={{ width: "100%" }}>
                        <div className="submission-history-card">
                          <div
                            className="d-flex"
                            style={{ alignItems: "center", paddingTop: "5%" }}
                          >
                            <div style={{ width: "70%" }} className="ml-1">
                              <h3
                                style={{
                                  fontWeight: "500",
                                  fontFamily: "Brandon Grotesque, Arial",
                                  fontSize: "15px",
                                  textAlign: "left",
                                  color: "#274193",
                                }}
                              >
                                {!ele.description
                                  ? ele.sourceType
                                  : ele.description}
                              </h3>
                              <p
                                className="t-black fontSize-14"
                                style={{
                                  textAlign: "left",
                                  marginTop: "3%",
                                  textTransform: "capitalize",
                                }}
                              >
                                {formatRelative(
                                  new Date(ele.createdAt),
                                  new Date()
                                )}
                              </p>
                            </div>
                            <div style={{ width: "30%", paddingRight: "3%" }}>
                              {ele.operation === "DEBIT" ? (
                                <p
                                  style={{
                                    fontWeight: "600",
                                    textAlign: "right",
                                    color: "black",
                                  }}
                                >
                                  -{ele.value} KiKs
                                </p>
                              ) : ele.operation === "CREDIT" ? (
                                <p
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "600",
                                    color: "black",
                                  }}
                                  className="fontSize-16"
                                >
                                  +{ele.value} KiKs
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="mt-5">
              <h3 className="text-center">No history shown yet</h3>
            </div>
          )
        ) : null}

        {tab === "Game" && gameHistory.length <= 0 && (
          <div className="mt-5">
            <h3 className="text-center t-black">
              No recent game activity shown yet
            </h3>
          </div>
        )}

        {tab === "Game" && gameHistory?.length > 0
          ? gameHistory.map((ele, index) => {
              if (ele.getSubmissionInfo.type !== "GAMES") {
                return null;
              }
              return (
                <div key={index}>
                  <div className="mt-2">
                    <div
                      style={{
                        boxShadow: "0px 2px 18px rgba(0, 0, 0, 0.16)",
                        borderRadius: "8px",
                      }}
                    >
                      <div className="text-center" style={{ width: "100%" }}>
                        <div className="submission-history-card">
                          <div
                            className="d-flex"
                            style={{ alignItems: "center", paddingTop: "5%" }}
                          >
                            {/* <div
                              style={{
                                textAlign: "left",
                                width: "30%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                style={{
                                  width: "100px",
                                  height: "90px",
                                  objectFit: "contain",
                                }}
                                src={notAvailableImg}
                                alt=""
                              />
                            </div> */}
                            <div style={{ width: "70%" }} className="ml-1">
                              <h3
                                style={{
                                  fontWeight: "500",
                                  fontFamily: "Brandon Grotesque, Arial",
                                  fontSize: "15px",
                                  textAlign: "left",
                                  color: "#274193",
                                }}
                              >
                                {ele.getSubmissionInfo.campaignId ===
                                "d4077e42-2114-41f2-9280-de41ece1b73c"
                                  ? "SPIN N WIN (CNY)"
                                  : ele.getSubmissionInfo.campaignId ===
                                    "c22500c9-a468-4435-8f4d-cc149fe502c5"
                                  ? "SPIN N WIN (RAYA)"
                                  : "Unknown game"}
                              </h3>
                              <p
                                className="t-black fontSize-14"
                                style={{
                                  textAlign: "left",
                                  marginTop: "3%",
                                  textTransform: "capitalize",
                                }}
                              >
                                {ele?.getSubmissionInfo?.createdAt
                                  ? formatRelative(
                                      new Date(
                                        ele?.getSubmissionInfo?.createdAt
                                      ),
                                      new Date()
                                    )
                                  : null}
                              </p>
                            </div>
                            <div style={{ width: "30%", paddingRight: "3%" }}>
                              <p
                                className="text-center"
                                style={{
                                  // fontSize: "16px",
                                  fontWeight: "600",
                                  color: "green",
                                }}
                              >
                                {ele.getSubmissionInfo.status}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </div>

      <UserFooter type={"submissionsPage"} />
    </div>
  );
};

export default Submissions;
