import React, { useState, useEffect } from "react";
import { Button, Checkbox, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import closeBtn from "../../images/CloseBtn.svg";
import { ShopOutlined } from "@ant-design/icons";
import pickup from "../../images/pickup.svg";
import SubHeader from "../../components/SubHeader";
import "../../css/Cart.css";
// import Whatsapp from "../../components/Whatsapp";
import axiosClient from "../../utils/axiosClient";
import { objectToURLSearchParamsString } from "../../utils/axiosHelper";

const CartInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [userCarts, setUserCarts] = useState([]);
  const [totalPoint, setTotalPoint] = useState(0);
  const [isAbleToCheckout, setIsAbleToCheckout] = useState(false);
  const [cartsSummaryErrors, setCartsSummaryErrors] = useState([]);
  const [selectedCarts, setSelectedCarts] = useState([]);
  const [userAddress, setUserAddress] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [forceRenderKey, setForceRenderKey] = useState(0);

  const fetchUserAddress = () => {
    const params = {
    	isActive: true,
    };
    const queryString = objectToURLSearchParamsString(params);
    axiosClient
    	.get('/address?' + queryString)
    	.then((result) => {
    		if (result?.data?.data && Array.isArray(result?.data?.data)) {
    			setUserAddress(result?.data?.data);
    		} else {
    			console.error('result: ', result);
    		}
    	})
    	.catch((err) => {
    		console.error('fetchUserCartsSummary: ', err);
    		message.error(err?.message || 'Error occur on fetching address');
    	});
  };

  const fetchUserCartsSummary = () => {
    axiosClient
      .get("/carts/summary")
      .then((result) => {
        if (result?.data?.data) {

          Array.isArray(result.data.data.cartItems) &&
            setUserCarts(result.data.data.cartItems);
          (result.data.data.isAbleToCheckout !== undefined ||
            result.data.data.isAbleToCheckout !== null) &&
            setIsAbleToCheckout(result.data.data.isAbleToCheckout);

          setTotalPoint(result.data.data.totalPoints || 0);

          Array.isArray(result.data.data.cartsSummaryErrors) &&
            setCartsSummaryErrors(result.data.data.cartsSummaryErrors);

          Array.isArray(result.data.data.selectedCarts) &&
            setSelectedCarts(result.data.data.selectedCarts);
        } else {
          console.error("result: ", result);
        }
      })
      .catch((err) => {
        console.error("fetchUserCartsSummary: ", err);
        message.error(err?.message || "Error occur on fetching carts");
      });
  };

  useEffect(() => {
    if (!isLoading) {
      Promise.all([fetchUserCartsSummary(), fetchUserAddress()]).finally(() =>
        setIsLoading(false)
      );
    }
  }, [isLoading]);

  const handleIncrement = (item) => {
    updateCart({
      cartId: item.id,
      qty: item.qty + 1,
    });
    setForceRenderKey((prev) => prev + 1);
  };

  const updateCart = (_params) => {
    setIsLoading(true);

    axiosClient
      .put("/carts", _params)
      .then((result) => {
        message.success("Succesfully Update");
      })
      .catch((err) => {
        console.error("updateCart: ", err);
        message.error(
          err?.response?.data?.message || "Error occur on update cart"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDecrement = (item) => {
    if (item.qty <= 1) {
      if (window.confirm("Remove Cart?")) {
        updateCart({
          cartId: item.id,
          qty: item.qty - 1,
        });
      }
    } else {
      updateCart({
        cartId: item.id,
        qty: item.qty - 1,
      });
    }
    setForceRenderKey((prev) => prev + 1);
  };

  const handleDeleteCart = (_cartId) => {
    if (window.confirm("Remove item from cart?")) {
      setIsLoading(true);

      axiosClient
        .delete("/carts/" + _cartId)
        .then((result) => {
          setUserCarts((prev) => prev.filter((_s) => _s.id !== _cartId));
          message.success("Succesfully removed");
        })
        .catch((err) => {
          console.error("handleDeleteCart: ", err);
          message.error(err?.message || "Error occur on removing cart");
        })
        .finally(() => {
          setIsLoading(false);
          setForceRenderKey((prev) => prev + 1);
        });
    }
  };

  const handleAddToCart = () => {
    const hasProduct = selectedCarts.some(
			(cart) => cart?.shopItem?.reward?.type === 'PRODUCT'
		);
		const hasVoucher = selectedCarts.some(
			(cart) => cart?.shopItem?.reward?.type === 'VOUCHER'
		);

		if (hasVoucher && !hasProduct) {
			navigate('/cartSummary');
		} else if (hasProduct) {
			if (!userAddress.length) {
				navigate('/add/address', { state: { location } });
			} else {
				navigate('/deliveryAddress');
			}
		}
  };

  const CartItem = ({ data: _cartItem }) => {
    return (
      <div>
        <div
          style={{
            borderRadius: "6px",
            backgroundColor: "#FFF",
            width: "100%",
            boxShadow: "0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
            marginTop: "5%",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              gap: "10px",
            }}
          >
            <div
              style={{
                opacity: _cartItem.cartError ? "0.5" : "1",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
                justifyContent: "left",
              }}
            >
              <Checkbox
                onChange={(e) => {
                  updateCart({
                    cartId: _cartItem.id,
                    isSelected: !_cartItem.isSelected,
                  });
                }}
                checked={_cartItem.isSelected}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                marginTop: "13px",
              }}
            >
              <div
                style={{
                  background: "#D7D7D7",
                  display: "flex",
                  justifyContent: "center",
                  width: "100px",
                  height: "70px",
                }}
                className="ml-1"
              >
                <img
                  // src={_cartItem?.shopItem?.reward?.imageUrl}
                  src={pickup}
                  alt=""
                  style={{
                    width: "70%",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  lineHeight: "30px",
                }}
                className="ml-2"
              >
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                  }}
                >
                  {/* TNG */}
                  {_cartItem?.shopItem?.name}
                </p>
                <p
                  style={{
                    fontWeight: "",
                    fontSize: "16px",
                  }}
                >
                  <b style={{ fontSize: "18px" }}>
                    {_cartItem?.shopItem?.priceValue}
                    {/* 50 */}
                  </b>{" "}
                  KiKs
                </p>
                {_cartItem?.cartError && (
                  <p style={{ color: "red" }}>{_cartItem?.cartError}</p>
                )}
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "5px",
            }}
          >
            {/* item.pickup_optional ? (
														<div
															style={{
																borderRadius: '4px',
																backgroundColor: '#F5F5F5',
																width: '150px',
															}}
															className="mt-1 ml-1"
														>
															<div
																style={{
																	justifyContent: 'left',
																	display: 'flex',
																	alignItems: 'center',
																	gap: '3px',
																}}
															>
																<img
																	src={pickup}
																	alt=""
																	style={{ width: '15%', marginLeft: '5px' }}
																/>
																<p
																	className="fontSize-12"
																	style={{ fontWeight: '400' }}
																>
																	Available for pick-up
																</p>
															</div>
														</div>
													) :  ( */}
            {/* {_cartItem?.shopItem?.reward?.isShippable ? (
							<div
								style={{
									borderRadius: '4px',
									backgroundColor: '#F5F5F5',
									width: '30%',
								}}
								className="mt-1 ml-1"
							>
								<div
									style={{
										justifyContent: 'left',
										display: 'flex',
										alignItems: 'center',
										gap: '3px',
									}}
								>
									<img
										src={pickup}
										alt=""
										style={{ width: '15%', marginLeft: '5px' }}
									/>
									<p className="fontSize-12" style={{ fontWeight: '400' }}>
										Delivery Only
									</p>
								</div>
							</div>
						) : ( */}
            <div
              style={{
                borderRadius: "4px",
                backgroundColor: "#F5F5F5",
                width: "38%",
              }}
              className="mt-1 ml-1"
            >
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  gap: "1%",
                }}
              >
                <img
                  src={pickup}
                  alt=""
                  style={{ width: "15%", marginLeft: "5px" }}
                />
                <p className="fontSize-12" style={{ fontWeight: "400" }}>
                  Digital Rewards
                </p>
              </div>
            </div>
            {/* )} */}

            <div
              style={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
              }}
              className="mr-1"
            >
              <button
                onClick={() => handleDecrement(_cartItem)}
                className="adjust-quantity-btn"
              >
                -
              </button>
              <input
                disabled
                type="number"
                value={_cartItem.qty}
                style={{
                  width: "10%",
                  textAlign: "center",
                  border: "none",
                  backgroundColor: "transparent",
                  fontSize: "16px",
                }}
              />
              <button
                onClick={() => handleIncrement(_cartItem)}
                className="adjust-quantity-btn"
              >
                +
              </button>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: "-15px",
              right: "-15px",
              margin: "5px",
            }}
            onClick={() => handleDeleteCart(_cartItem.id)}
          >
            <img
              src={closeBtn}
              alt=""
              style={{
                borderRadius: "50px",
                background: "rgba(236, 236, 236, 0.80)",
                width: "20px",
                border: "3px solid rgba(236, 236, 236, 0.80)",
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const productCarts = userCarts.filter(
  	(_cart) => _cart?.shopItem?.reward?.type === 'PRODUCT'
  );

  const voucherCarts = userCarts.filter(
    (_cart) => _cart?.shopItem?.reward?.type === "VOUCHER"
  );

  return (
    <div
      style={{
        opacity: isLoading ? "0.5" : "1",
      }}
    >
      <SubHeader setPreviousPage={-1} forceRenderKey={forceRenderKey} />
      <div className="mt-2">
        <p
          style={{ fontSize: "25px", fontWeight: "500", textAlign: "left" }}
          className="ml-2"
        >
          Cart
        </p>
      </div>

      <div className="ml-2 mr-2 mt-2">
        {userCarts && userCarts.length ? (
          <>
            {/* VOUCHER CART ITEMS */}
            <div style={{ marginBottom: "10%" }}>
              {voucherCarts.length > 0 && (
                <div>
                  <p style={{ fontSize: "20px", fontWeight: "500" }}>
                    Digital Rewards
                  </p>
                </div>
              )}

              {React.Children.toArray(
                voucherCarts.map((item) => <CartItem data={item} />)
              )}
            </div>

            {/* PRODUCT CART ITEMS */}
            <div style={{ marginBottom: '10%' }}>
							{productCarts.length > 0 && (
								<div>
									<p style={{ fontSize: '20px', fontWeight: '500' }}>
										Physical Rewards
									</p>
								</div>
							)}

							{React.Children.toArray(
								productCarts.map((item) => <CartItem data={item} />)
							)}
						</div>

            {/* TOTAL AND POINT */}
            <div>
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                className="mt-3"
              >
                <div>
                  <p style={{ fontSize: "20px", fontWeight: "300" }}>Total: </p>
                </div>
                <div>
                  <p style={{ fontSize: "20px", fontWeight: "700" }}>
                    {totalPoint} KiKs
                  </p>
                </div>
              </div>
            </div>

            {/* SHOW CARTS ERROR IF ANY */}
            {cartsSummaryErrors.length > 0 &&
              React.Children.toArray(
                cartsSummaryErrors.map((_err) => (
                  <p
                    style={{
                      color: "red",
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    {_err}
                  </p>
                ))
              )}

            {/* CHECKOUT */}
            <div className="mt-5">
              <Button
                disabled={!isAbleToCheckout}
                style={{
                  opacity: !isAbleToCheckout ? "0.5" : "1",
                  borderRadius: "5px",
                  background: !isAbleToCheckout ? "grey" : "#274193",
                  width: "100%",
                  fontSize: "16px",
                  fontWeight: "500",
                  height: "45px",
                  marginBottom: "50px",
                  color: !isAbleToCheckout ? "black" : "white",
                }}
                onClick={handleAddToCart}
              >
                Proceed to Checkout
              </Button>
              {/* <div
								style={{
									position: 'relative',
									top: '20px',
									left: '0',
									width: '90%',
								}}
							>
								<Whatsapp />
							</div> */}
            </div>
          </>
        ) : (
          <div style={{ paddingTop: "120px" }}>
            <ShopOutlined
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "80px",
                color: "darkgray",
              }}
            />
            <h1 className="text-center mt-2" style={{ fontSize: "25px" }}>
              Your Cart is Currently Empty
            </h1>
            <p className="text-center mt-2 ml-3 mr-3 fontSize-16">
              Before proceeding to the checkout page, ensure you have added
              eveything you need in this cart.
            </p>
            <div
              className="mt-5"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                style={{
                  backgroundColor: "black",
                  border: "none",
                  color: "white",
                  height: "45px",
                  width: "70%",
                  fontWeight: "600",
                }}
                onClick={() => navigate("/kikshop")}
              >
                Return to Kik Shop
              </Button>
            </div>
          </div>
        )}

        {/* 
				
					{/* { items?.length && items.some(item => item.categoryName === "itemCategory") ? (
                    
                            <div>
                            <div className="mt-2"> 
                                <p style={{ fontSize:'18px', fontWeight:'bold' }}>Redemption Method</p>
                            </div>
                
                            <div style={{ display:'grid', gridTemplateColumns:'1fr 1fr', gridGap:'20px', marginTop:'20px' }}>
                
                            <div
                                style = {{ 
                                display:'flex', 
                                height: '100px', 
                                backgroundColor: deliveryMethod === 'delivery' ? 'black' : '#F2F2F2', 
                                borderRadius:'8px', 
                                justifyContent:'space-between', 
                                alignItems:'center' ,
                                color:  deliveryMethod === 'delivery' ? 'white' : 'black',
                                pointerEvents: 
                                    items.length && 
                                    ((items.checked === true && items.some(item => item.categoryName === "itemCategory") && items.some(item => item.categoryName === "voucherCategory")) || 
                                    (items.checked === true && items.some(item => item.categoryName === "itemCategory") && items.some(item => item.categoryName !== "voucherCategory"))) ? 
                                    'auto' : 'none',
                                }}
                                onClick = {handleDeliveryClick}
                            >
                                <div>
                                <h1 style={{ fontSize:'16px', fontWeight:'500', marginLeft:'20px' }}>Delivery</h1>
                                </div>
                                <div style={{ position:'relative', left:'8px'}}>
                                {deliveryMethod === "delivery" ? <img src={isDelivery} alt="" style={{width:'80px'}}/> : <img src={delivery} alt="" style={{width:'80px'}}/>}
                                </div>
                            </div>
                
                            <div 
                                style={{ 
                                    display:'flex',
                                    height: '100px', 
                                    backgroundColor: items.some(item => item.pickup_optional === "N") ? '#F2F2F2' : deliveryMethod === 'pickup' ? 'black' : '#F2F2F2' ,
                                    borderRadius:'8px', 
                                    justifyContent:'space-evenly', 
                                    alignItems:'center',
                                    color: items.some(item => item.pickup_optional === "N") ? 'black' : deliveryMethod === 'pickup' ? 'white' : 'black',
                                    pointerEvents: 
                                    items.length && ((items.some(item => item.categoryName === "itemCategory") && items.some(item => item.categoryName === "voucherCategory"))|| (items.some(item => item.categoryName === "itemCategory") && items.some(item => item.categoryName !== "voucherCategory")))? 
                                    'auto'
                                    : 
                                    'none',
                                }}

                                onClick={() => {
                                    const hasPickUp = items.some(item => item.pickup_optional === "Y");
                                    const hasNoPickUp = items.some(item => item.pickup_optional === "N");
                                    const hasVoucher = items.some(item => item.categoryName === "voucherCategory");
                                    const hasItem = items.some(item => item.categoryName === "itemCategory");
                                    if (hasPickUp && hasNoPickUp) {
                                        setDeliveryMethod(null);
                                    } 

                                    else if(hasVoucher && !hasItem) {
                                        setDeliveryMethod('Redeemed');
                                    }
                                    
                                    else if((hasPickUp && hasVoucher && !hasNoPickUp) || (hasPickUp && !hasNoPickUp)){
                                        setDeliveryMethod('pickup');
                                    }

                                    else {
                                        setDeliveryMethod('');
                                    }
                                }}
                            >
                                <div>
                                <h1 style={{ fontSize:'16px', fontWeight:'500', marginLeft:'20px' }}>Pick Up </h1>
                                </div>
                                    <div style={{ alignItems:'center', position:'relative', left:'20px' }}>
                                        {   items.some(item => item.pickup_optional === "Y" && item.pickup_optional !== "N") &&
                                            !items.some(item => item.pickup_optional === "N" && item.pickup_optional !== "Y") 
                                            ? 
                                        (
                                            <img src={deliveryMethod === 'pickup' ? successImg : pickup} alt="" style={{ width: '90px' }} />
                                        ) : (
                                            <img src={pickup} alt="" style={{ width: '90px' }} />
                                        )}
                                    </div>
                            </div>
                            </div>

                                {showMsg && <p style={{ color:'red', textAlign:'center' }}>{showMsg}</p>}

                                {items.some(item => item.pickup_optional === "N") && items.some(item => item.pickup_optional === "Y")  ?
                                    <p style={{ color:'red', textAlign:'center', fontWeight:'400', marginTop:'2%' }} className='fontSize-12'>
                                        Your order contains "Delivery-Only" item(s), please unselect<br/> the item(s) to enable "Pick Up" option.
                                    </p>
                                : items.some(item => item.pickup_optional === "N" && item.pickup_optional !== "Y") ? 
                                    <p style={{ color:'red', textAlign:'center', fontWeight:'400', marginTop:'2%' }} className='fontSize-12'>
                                        Your order contains "Delivery-Only" item(s).
                                    </p>
                                :
                                    null
                                }
                                {items.some(item => item.pickup_optional === "N") && items.some(item => item.pickup_optional === "Y")  ?
                                    <p style={{ color:'red', textAlign:'center', fontWeight:'400', marginTop:'2%' }} className='fontSize-12'>
                                        Your order contains "Delivery-Only" item(s), please unselect<br/> the item(s) to enable "Pick Up" option.
                                    </p>
                                :
                                    null
                                }
                            </div>
                        )
                    :
                        null
                } */}
      </div>
    </div>
  );
};

export default CartInfo;
