import "./App.css";
import { USER } from "./enums";
import {
  // BrowserRouter,
  // Navigate,
  Route,
  Routes,
  // useNavigate,
} from "react-router-dom";
// import { Layout, Menu } from "antd";
// import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import React, { Suspense, lazy, useEffect, useState } from "react";
import AddUpdateAddress from "./view/Profile/AddUpdateAddress";
import AddressMainPage from "./view/Profile/AddressMainPage";
// import ArticlePage from "./view/Home/Article";
import AuthRedirect from "./utilities/AuthRedirect";
import CartInfoPage from "./view/Cart/CartInfo";
import CartSuccessPage from "./view/Cart/SummarySuccess";
// import BadgesPage from './view/Profile/Badges';
// import ExplorerBadgePage from './view/Profile/ExplorerBadge';
// import LanguagePage from './view/Profile/Language';
import CartSummaryPage from "./view/Cart/CartSummary";
import DeliveryAddressPage from "./view/Cart/DeliveryAddress";
// import GetStartedPage from "./view/Onboarding/GetStarted";
// import HelpCenter from "./view/Profile/HelpCenter";
import Home from "./view/Home/Home";
// import LoadingPage from "./view/Onboarding/Loading";
import LoginPage from "./view/Register/LoginPage";
import LoginVerify from "./view/Home/LoginVerify";
// import ParticipatingStoresPage from "./view/Home/ParticipatingStores";
import PersonalDetails from "./view/Profile/PersonalDetails";
import PointShopPage from "./view/Home/PointShop";
import ProfilePage from "./view/Profile/ProfilePage";
import ReferAFriendPage from "./view/Profile/ReferAFriend";
import Register from "./view/Home/Register";
import Rewards from "./view/Home/Rewards";
import RewardsItem from "./view/Home/RewardItems";
// import StoreLocatorPage from "./view/Home/StoreLocator";
import Submissions from "./view/Home/Submissions";
// import TieringPage from "./view/Profile/TieringPage";
import UpdateAddressPage from "./view/Profile/UpdateAddress";
// import Upload from "./view/Home/UploadReceipt";
// import WelcomePage from "./view/Onboarding/Welcome";
// import Whatsapp from "./components/Whatsapp";
import { clearError } from "./reducer/userDetailsReducer";
import { useDispatch } from "react-redux";
import Game from "./view/Home/Game";
import Scanqr from "./view/Home/Scanqr";
import GameType from "./view/Home/GameType";
import GetStarted from "./view/Onboarding/GetStarted";

const packageJson = require("../package.json");

function App() {
  const dispatch = useDispatch();
  const location = window.location.pathname;

  useEffect(() => {
    dispatch(clearError());
  }, [dispatch, location]);

  const isDev = process.env.REACT_APP_ENV === "dev";

  return (
    <div>
      {isDev && (
        <p
          style={{
            textAlign: "center",
            color: "orange",
            background: "black",
            position: "fixed",
            left: "50%",
            zIndex: 10000,
            padding: "2px 4px",
            pointerEvents: "none",
            cursor: "default",
          }}
        >
          v-{packageJson.version}
        </p>
      )}
      <div className="App">
        <Routes>
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/register" element={<Register />} />
          {/* <Route exact path="/loading" element={<LoadingPage />} /> */}
          <Route
            exact
            path="/"
            element={
              <AuthRedirect role={USER}>
                <Home />{" "}
              </AuthRedirect>
            }
          />
          {/* <Route exact path="/welcome" element={<WelcomePage />} /> */}
          {/* <Route exact path="/test-game" element={<GetStarted />} /> */}
          <Route exact path="/verify-otp" element={<LoginVerify />} />
          <Route
            exact
            path="/home"
            element={
              <AuthRedirect role={USER}>
                <Home />{" "}
              </AuthRedirect>
            }
          />
          <Route
            exact
            path="/game"
            element={
              <AuthRedirect role={USER}>
                <Game />{" "}
              </AuthRedirect>
            }
          />
          <Route
            exact
            path="/game/:id"
            element={
              <AuthRedirect role={USER}>
                <GameType />{" "}
              </AuthRedirect>
            }
          />
          <Route
            exact
            path="/rewards"
            element={
              <AuthRedirect role={USER}>
                <Rewards />{" "}
              </AuthRedirect>
            }
          />
          <Route
            exact
            path="/kikshop"
            element={
              <AuthRedirect role={USER}>
                <PointShopPage />{" "}
              </AuthRedirect>
            }
          />
          <Route
            exact
            path="/rewards/items/:id"
            element={
              <AuthRedirect role={USER}>
                <RewardsItem />{" "}
              </AuthRedirect>
            }
          />
          <Route
            exact
            path="/submissions"
            element={
              <AuthRedirect role={USER}>
                <Submissions />{" "}
              </AuthRedirect>
            }
          />
          <Route
            exact
            path="/profile"
            element={
              <AuthRedirect role={USER}>
                <ProfilePage />{" "}
              </AuthRedirect>
            }
          />
          <Route
            exact
            path="/personal/info"
            element={
              <AuthRedirect role={USER}>
                <PersonalDetails />{" "}
              </AuthRedirect>
            }
          />
          <Route
            exact
            path="/referral-program"
            element={
              <AuthRedirect role={USER}>
                <ReferAFriendPage />{" "}
              </AuthRedirect>
            }
          />

          <Route
            exact
            path="/cartSummary"
            element={
              <AuthRedirect role={USER}>
                <CartSummaryPage />{" "}
              </AuthRedirect>
            }
          />

          <Route
            exact
            path="/cartSuccess"
            element={
              <AuthRedirect role={USER}>
                <CartSuccessPage />{" "}
              </AuthRedirect>
            }
          />
          <Route
            exact
            path="/scan-qr"
            element={
              <AuthRedirect role={USER}>
              <Scanqr />
              </AuthRedirect>
            }
          />
          <Route
            exact
            path="/cartInfo"
            element={
              <AuthRedirect role={USER}>
                <CartInfoPage />{" "}
              </AuthRedirect>
            }
          />
          <Route exact path="*" element={<LoginPage />} />
          {/* <Route
            exact
            path="/article"
            element={
              <AuthRedirect role={USER}>
                <ArticlePage />{" "}
              </AuthRedirect>
            }
          /> */}
          {/* <Route
            exact
            path="/tiering"
            element={
              <AuthRedirect role={USER}>
                <TieringPage />{" "}
              </AuthRedirect>
            }
          /> */}

          {/* <Route exact path="/language" element={Auth <LanguagePage /> } /> */}
          {/* <Route exact path="/badges" element={Auth <BadgesPage /> } /> */}
          {/* <Route exact path="/explorerBadge" element={Auth <ExplorerBadgePage /> } /> */}

          {/* <Route
            exact
            path="/help"
            element={
              <AuthRedirect role={USER}>
                <HelpCenter />{" "}
              </AuthRedirect>
            }
          /> */}
          <Route
            exact
            path="/address"
            element={
              <AuthRedirect role={USER}>
                <AddressMainPage />
              </AuthRedirect>
            }
          />
          <Route
            exact
            path="/add/address"
            element={
              <AuthRedirect role={USER}>
                <AddUpdateAddress />{" "}
              </AuthRedirect>
            }
          />
          <Route
            exact
            path="/update/address"
            element={
              <AuthRedirect role={USER}>
                <UpdateAddressPage />{" "}
              </AuthRedirect>
            }
          />
          <Route
            exact
            path="/deliveryAddress"
            element={
              <AuthRedirect role={USER}>
                <DeliveryAddressPage />{" "}
              </AuthRedirect>
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default App;
