import { useEffect, useRef, useState } from "react";
import { useParams, redirect } from "react-router-dom";
import Winwheel from "winwheel";
import { useDispatch } from "react-redux";
import { message, Modal, Button } from "antd";
import UserFooter from "../../components/UserFooter";
import "../../css/Spin.css";
import confirmation from "../../images/confirmation.svg";
import axiosClient from "../../utils/axiosClient";
import { fetchUserProfileIfNotExist } from "../../redux/user/userActions";
// import leftObj from "../../images/JomKik_Vectors CNY-01.png";
import leftObj from "../../images/raya2024/mascots.svg";
// import rightObj from "../../images/JomKik_Vectors CNY-02.png";
import gamebackground from "../../images/raya2024/background.png";
import wheelOverlay from "../../images/raya2024/JomKik_Raya Wheel B-01.png";
// import wheelStand from "../../images/wheel_stand.svg";
import spinNow from "../../images/spin_now.svg";
// import bottomImage from "../../images/raya2024/background_bottom.svg";
// import goldBag from "../../images/gold_bag.svg";

const GameType = () => {
  // const navigate = useNavigate();
  const [wheelSpinning, setWheelSpinning] = useState(false);
  const [theWheel, setTheWheel] = useState("");
  const [nav, setNav] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const canvasRef = useRef(null);
  const [allPrizes, setAllPrizes] = useState();
  const [selectedPrize, setSelectedPrize] = useState("");
  const { id } = useParams();
  const [loadingModal, setLoadingModal] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingModal(false);
    }, 2500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchSpinAndWinPrizes = async () => {
      try {
        const res = await axiosClient.get("/campaigns/" + id + "/validity");
        if (res && res.data && res.data.data) {
          const spinAndWinCampaign = res.data.data;
          if (spinAndWinCampaign) {
            setCampaign(res.data.data.campaign);
            setAllPrizes(spinAndWinCampaign.rewards);
          }
        } else {
          redirect("/game");
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchSpinAndWinPrizes();
  }, [id]);

  useEffect(() => {
    if (allPrizes && allPrizes.length > 0) {
      const segmentText = allPrizes.map((prize, index) => ({
        text: prize.name,
        // fillStyle: index % 2 === 0 ? "#d32a29" : "#fde5c6",
        fillStyle: index % 2 === 0 ? "#219D59" : "#fde5c6",
        textFillStyle: index % 2 === 0 ? "#ffdc5a" : "#274193",
        strokeStyle: "#f8c34c",
        textShadowColor: index % 2 === 0 ? "#ffdc5a" : "#274193",
      }));

      const canvasContext = canvasRef.current.getContext("2d");

      let wheel = new Winwheel({
        drawMode: "text",
        outerRadius: 170,
        centerX: 180,
        centerY: 190,
        textBaseline: "bottom",
        animation: {
          type: "spinToStop",
          duration: 5,
          spins: 8,
          callbackFinished: alertPrize,
        },
        segments: segmentText,
        numSegments: allPrizes?.length || 0,
        textFontSize: 8.5,
        textFontWeight: 800,
        lineWidth: 5,
        textShadow: true,
        // segments: segmentText,
        // numSegments: allPrizes?.length || 0,
        // textFontSize: 9, // Adjust font size as needed
        // textFontFamily: "Arial", // Specify a clear font family
        // textFontWeight: "bold", // Optionally make the font bold
        // textFillStyle: "#000", // Text color
        // textShadow: true, // Enable text shadow
        // textShadowOffsetX: 1,
        // textShadowOffsetY: 1,
        // textShadowColor: "#fff", // Shadow color
        // lineWidth: 3,
      });

      wheel.draw(canvasContext);
      setTheWheel(wheel);
    }
  }, [allPrizes]);

  useEffect(() => {
    dispatch(fetchUserProfileIfNotExist());
  }, []);

  const [campaign, setCampaign] = useState();

  const handleYesClick = async () => {
    setWheelSpinning(true);
    try {
      const res = await axiosClient.post("/game/start", {
        campaignId: id,
      });

      const rewards = res.data.data.rewards;
      if (rewards && rewards.length > 0) {
        const prizeName = res.data.data.rewards[0].name;
        const prizeDescription = res.data.data.rewards[0].description;
        setSelectedPrize(prizeDescription);
        startSpin({ prizeName, prizeDescription });
        // setWheelSpinning(true);
        setConfirm(false);
      }
    } catch (error) {
      setError(error?.response?.data?.message);
      setTimeout(() => {
        setError("");
      }, 1500);
      console.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {}, [selectedPrize]);

  const alertPrize = () => {
    if (selectedPrize !== undefined) {
      setNav(true);
      setTimeout(() => {
        theWheel.rotationAngle = 0;
        theWheel.draw(canvasRef.current.getContext("2d"));
      }, 500);
    } else {
      message.error("prize not found!");
    }
  };

  const startSpin = async ({ prizeName, prizeDescription }) => {
    // Check if the wheel is already spinning, if yes, return
    if (wheelSpinning) {
      return;
    }
    setWheelSpinning(true);
    try {
      const stopAtIndex = allPrizes.findIndex(
        (prize) => prize.name === prizeName
      );
      const totalSegments = allPrizes.length;
      const segmentAngle = 360 / totalSegments;

      // Calculate the middle of the segment
      const stopAngle = stopAtIndex * segmentAngle + segmentAngle / 2;

      theWheel.animation.stopAngle = stopAngle;
      theWheel.startAnimation(true);
      // Wait for the spinning animation to complete
      await new Promise((resolve) => setTimeout(resolve, 5300));
      // Stop the animation and handle the prize
      theWheel.stopAnimation(false);
      alertPrize(prizeDescription);
    } catch (error) {
      setError(error?.response?.data?.message);
      setTimeout(() => {
        setError("");
      }, 1500);
      console.error(error?.response?.data?.message);
    } finally {
      setWheelSpinning(false);
    }
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        // height: "calc(100vh - 47.5px)",
        height: "calc(100vh - 5.5px)",
        backgroundColor: "#F9F9F9",
        backgroundImage: `url(${gamebackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "bottom",
      }}
    >
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: "1000",
          pointerEvents: "none",
          paddingTop: "10%",
        }}
      >
        <img src={leftObj} alt="mc" className="with-wind-spinner-mascot" />
        {/* <img
          src={rightObj}
          alt="lionDance"
          className="lion-dance-mascot"
        /> */}
        {/* <img
          src={goldBag}
          alt="goldBag"
          style={{
            width: "23%",
            position: "absolute",
            bottom: "7%",
            right: "0",
            zIndex: "100",
          }}
        /> */}
        {/* <img
          src={bottomImage}
          alt="bottom-background-img"
          className="bottom-image-bg"
        /> */}
      </div>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          backgroundColor: wheelSpinning
            ? "rgba(0,0,0,0.5)"
            : "rgba(0,0,0,0.0)",
        }}
      >
        <div
          style={{
            width: "100%",
            position: "absolute",
            // top: "27%",
            left: "0",
            zIndex: "999",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              top: "2%",
            }}
          >
            <canvas
              id="canvas"
              ref={canvasRef}
              height="380"
              width="360"
              style={{
                width: "100%",
                height: "auto",
                zIndex: "800",
                // position: "relative",
                // left:'10%',
                // position: "relative",
                // paddingTop:'6%'
              }}
            />
            {/* <img
              src={wheelStand}
              style={{
                bottom: "-10%",
                position: "absolute",
                zIndex: "",
                width: "60%",
              }}
              alt=""
            /> */}
            <img
              src={wheelOverlay}
              style={{
                // top: "4%",
                position: "absolute",
                zIndex: "999",
                pointerEvents: "none",
                width: "111%",
                // marginBottom:'10%',
              }}
              alt=""
            />
            <div
              style={{
                position: "absolute",
                zIndex: "999",
                // border: "2px solid orange",
                // background:'red',
                width: "27%",
                height: "16%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                lineHeight: "1.1",
              }}
              onClick={() => {
                if (wheelSpinning) {
                  return;
                } else {
                  setConfirm(true);
                }
              }}
            >
              {/* SPIN NOW */}
              <img
                src={spinNow}
                alt=""
                style={{
                  position: "absolute",
                  zIndex: "1000",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "2%",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <UserFooter />
      <Modal open={confirm} centered footer={null} closable={false} width={300}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={confirmation} alt="" style={{ width: "20%" }}></img>
        </div>
        <br />
        <div
          style={{
            fontWeight: "500",
            fontSize: "15px",
            textAlign: "center",
          }}
        >
          Do you want to proceed Spin & Win with{" "}
          <b style={{ color: "red" }}>
            {campaign?.config?.requiredToken}{" "}
            {campaign?.config?.requiredToken > 1 ? "Tokens" : "Token"}
          </b>
          ?
        </div>

        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "4%",
            }}
          >
            <Button
              disabled={wheelSpinning}
              onClick={handleYesClick}
              style={{
                width: "90%",
                marginRight: "3%",
                backgroundColor: "#274193",
                color: "white",
                fontWeight: "600",
              }}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                setConfirm(false);
              }}
              style={{
                width: "90%",
                marginLeft: "3%",
                backgroundColor: "#274193",
                color: "white",
                fontWeight: "600",
              }}
            >
              No
            </Button>
          </div>
          {wheelSpinning && (
            <p className="text-center t-blue" style={{ paddingTop: "3%" }}>
              Please wait ...
            </p>
          )}
        </div>
        <div style={{ color: "red", textAlign: "center" }}>{error}</div>
      </Modal>

      <Modal open={nav} centered footer={null} closable={false} width={300}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={confirmation} alt="" style={{ width: "20%" }}></img>
        </div>
        <br />
        <div
          style={{
            fontWeight: "500",
            fontSize: "15px",
            textAlign: "center",
          }}
        >
          {/* You got{" "} */}
          {selectedPrize}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "4%",
          }}
        >
          <Button
            onClick={() => {
              setNav(false);
              setConfirm(true);
            }}
            style={{
              width: "90%",
              marginLeft: "3%",
              backgroundColor: "#274193",
              color: "white",
              fontWeight: "600",
            }}
          >
            Sounds Good!
          </Button>
        </div>
        <div style={{ color: "red", textAlign: "center" }}>{error}</div>
      </Modal>

      <Modal
        open={loadingModal}
        centered
        footer={null}
        closable={false}
        width={300}
      >
        <div
          style={{
            fontWeight: "500",
            fontSize: "15px",
            textAlign: "center",
            paddingTop: "20%",
            paddingBottom: "20%",
            color: "black",
          }}
        >
          Loading...{" "}
        </div>
        <div style={{ color: "red", textAlign: "center" }}>{error}</div>
      </Modal>
    </div>
  );
};

export default GameType;
