import './addEdit.css';

import { Button, Form, Input, Modal, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import SubHeader from '../../components/SubHeader';
import successImg from '../../images/checkmark.png';
import axiosClient from '../../utils/axiosClient';

const UpdateAddress = () => {
	const t = (string) => string;
	const navigate = useNavigate();
	let location = useLocation();
	const [form] = Form.useForm();
	const [params] = useSearchParams();
	const [submit, setSubmit] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const currentAddress = location.state?.address;
	// const { error, loading } = useSelector((state) => state.userDetails);
	const [addr, setAddr] = useState();
	const [name, setName] = useState(location.state.name);
	const [number, setNumber] = useState(location.state.phone);
	const [lineOne, setLineOne] = useState(location.state.line1);
	const [lineTwo, setLineTwo] = useState(location.state.line2);
	const [city, setCity] = useState(location.state.city);
	const [postcode, setPostcode] = useState(location.state.postcode);
	const [userId, setUserId] = useState(location.state.comUserId);
	const [addrId, setAddrId] = useState(location.state.id);
	const [errorName, setErrorName] = useState(' ');
	const [errorNumber, setErrorNumber] = useState(' ');
	const [errorPostcode, setErrorPostcode] = useState('');
	const [errorCity, setErrorCity] = useState('');

	const onFinish = async (addressUpdate) => {
		addressUpdate.id = location.state.id;
		// let updateInfo = { ...values, addrId, userId };
		try {
			const res = await axiosClient.post('/address/update', {
				addrUpdate: { ...addressUpdate },
			});
			if (res) {
				setIsSuccess(true);
			}
		} catch (error) {
			console.error('Error:', error);
			throw error;
		}
	};

	const isName = (e) => {
		var regex = /^[a-zA-Z ]+( [a-zA-Z ]+)*$/;
		// /^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/;
		setName(e.target.value);
		if (!regex.test(e.target.value)) {
			setErrorName('Invalid name format');
		} else {
			setErrorName('');
		}
		if (!e.target.value) {
			setErrorName('');
		}
	};

	const isNumber = (e) => {
		// eslint-disable-next-line no-useless-escape
		let regNumber = /^601[0-9]{8,9}$/;
		if (!regNumber.test(e.target.value)) {
			setErrorNumber('Invalid number format');
		} else {
			setErrorNumber('');
			setNumber(e.target.value);
		}
		if (!e.target.value) {
			setErrorNumber('');
		}
	};

	const isCity = (e) => {
		var regCity = /^[a-zA-Z ]+( [a-zA-Z ]+)*$/;
		if (!regCity.test(e.target.value)) {
			setErrorCity('Invalid city name');
		} else {
			setErrorCity('');
			setCity(e.target.value);
		}
		if (!e.target.value) {
			setErrorCity('');
		}
	};

	const isPostcode = (e) => {
		// console.log(e.target.value);
		let regPostcode = /^\d{5}$/;
		if (!regPostcode.test(e.target.value)) {
			setErrorPostcode('Invalid postcode');
		} else {
			setErrorPostcode('');
			setPostcode(e.target.value);
		}
		if (!e.target.value) {
			setErrorPostcode('');
		}
	};

	return (
		<div>
			<SubHeader setPreviousPage={-1} />

			<div className="mt-1">
				<hr />
			</div>

			<div className="ml-3 mr-3 mt-2">
				<div className="">
					<p className="delivery-details-font">
						{t('Delivery')} {t('Details')}
					</p>
					<p>Enter your address for delivery.</p>
				</div>

				<div>
					<div>
						<Form
							layout="vertical"
							name="add_update_address"
							form={form}
							onFinish={onFinish}
							autoComplete="off"
						>
							<div>
								<div className="mx-5 mt-2">
									<Form.Item
										name="name"
										initialValue={name}
										label={
											<label className="form-item-label">{t('Name')}</label>
										}
										rules={[
											{
												message: t('inputName'),
											},
										]}
									>
										<Input
											type="text"
											placeholder={t('Enter your name')}
											required
											onChange={isName}
										/>
									</Form.Item>
									{errorName ? (
										<>
											{' '}
											<div
												style={{
													color: 'red',
													marginTop: '-5%',
													textAlign: 'left',
												}}
											>
												{errorName}
											</div>
											<br />
										</>
									) : null}

									<Form.Item
										name="phone"
										initialValue={number}
										label={
											<label className="form-item-label">
												{t('Mobile Number')}
											</label>
										}
									>
										<Input
											type="number"
											placeholder="(E.g. 60122222222)"
											required
											onChange={isNumber}
										/>
									</Form.Item>
									{/* {errorNumber ? (
										<>
											{' '}
											<div
												style={{
													color: 'red',
													marginTop: '-5%',
													textAlign: 'left',
												}}
											>
												{errorNumber}
											</div>
											<br />
										</>
									) : null} */}

									<Form.Item
										name="line1"
										initialValue={lineOne}
										label={
											<label className="form-item-label">{t('Address')}</label>
										}
										rules={[
											{
												message: t('inputAddress'),
											},
										]}
										style={{ color: 'red' }}
									>
										<Input
											type="text"
											placeholder={t('Enter your address (line 1)')}
											required
										/>
									</Form.Item>
									<Form.Item initialValue={lineTwo} name="line2" label="">
										<Input
											type="text"
											placeholder={t('Enter your address (line 2)')}
										/>
									</Form.Item>

									<div
										className="d-flex"
										style={{ justifyContent: 'space-evenly', gap: '20px' }}
									>
										<Form.Item
											name="city"
											initialValue={city}
											label={
												<label className="form-item-label">{t('City')}</label>
											}
											rules={[
												{
													// required: true,
													message: t('inputCity'),
												},
											]}
											style={{ minWidth: '150px' }}
											className="w-100 ms-2"
										>
											<Input
												type="text"
												placeholder="City"
												required
												onChange={isCity}
											/>
										</Form.Item>

										<Form.Item
											name="postcode"
											initialValue={postcode}
											label={
												<label className="form-item-label">
													{t('ZIP/Postcode')}
												</label>
											}
											className="w-100"
											rules={[
												{
													message: t('inputPostcode'),
												},
											]}
										>
											<Input
												type="number"
												placeholder={t('58200')}
												required
												onChange={isPostcode}
											/>
										</Form.Item>
									</div>
									{errorCity ? (
										<div style={{ color: 'red', textAlign: 'left' }}>
											{errorCity}
										</div>
									) : null}
									{errorPostcode ? (
										<div style={{ color: 'red', textAlign: 'left' }}>
											{errorPostcode}
										</div>
									) : null}
								</div>
								<div style={{ height: '15vh' }}></div>
								<Button
									// loading={loading}
									htmlType="submit"
									className="submit-btn"
								>
									{t('Save')}
								</Button>
							</div>
						</Form>
					</div>
				</div>

				<div>
					<Modal
						open={isSuccess}
						centered
						header={null}
						footer={null}
						closable={false}
						width={330}
					>
						<div className="text-center">
							<img
								style={{ width: '20%' }}
								src={successImg}
								alt=""
								className="submit-success-icon"
							></img>
							<div className="submit-receipt-success-header">Success!</div>
							<div className="submit-pending-modal-font mt-1">
								<p className="fontSize-16 text-center">
									Address has been successfully updated.
								</p>
							</div>
							<Button
								className="receipt-success-button mt-1"
								style={{ backgroundColor: '#274193' }}
								onClick={() => navigate('/address')}
							>
								Done
							</Button>
						</div>
					</Modal>
				</div>
			</div>
		</div>
	);
};

export default UpdateAddress;
