import { useNavigate } from "react-router-dom";
import {
  GiftOutlined,
  HomeOutlined,
  ProfileOutlined,
  HistoryOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";

const UserFooter = ({ type }) => {
  const navigate = useNavigate();

  return (
    <div
      className="footer-container mt-5"
      style={{
        position: "fixed",
        bottom: "0",
        zIndex: "99999",
      }}
    >
      <div
        className="mt-1"
        style={{ width: "20%" }}
        onClick={() => navigate("/home")}
      >
        <HomeOutlined
          style={{
            fontSize: "28px",
            color: type === "homePage" ? "#f37955" : "white",
          }}
        />
        <p
          className="fontSize-12 text-center"
          style={{ color: type === "homePage" ? "#f37955" : "white" }}
        >
          Home
        </p>
      </div>

      <div
        className="text-center mt-1"
        style={{ width: "20%" }}
        onClick={() => navigate("/rewards")}
      >
        <GiftOutlined
          style={{
            fontSize: "28px",
            color: type === "rewardsPage" ? "#f37955" : "white",
          }}
        />
        <p
          className="fontSize-12 text-center"
          style={{ color: type === "rewardsPage" ? "#f37955" : "white" }}
        >
          Rewards
        </p>
      </div>

      <div
        className="text-center mt-1"
        style={{ width: "20%" }}
        onClick={() => navigate("/scan-qr")}
      >
        <QrcodeOutlined
          style={{
            fontSize: "28px",
            color: type === "scanQr" ? "#f37955" : "white",
          }}
        />
        <p
          className="fontSize-12 text-center"
          style={{ color: type === "scanQr" ? "#f37955" : "white" }}
        >
          QR Scan
        </p>
      </div>

      <div
        className="text-center mt-1"
        onClick={() => navigate("/submissions")}
        style={{ width: "20%" }}
      >
        <HistoryOutlined
          style={{
            fontSize: "28px",
            color: type === "submissionsPage" ? "#f37955" : "white",
          }}
        />
        <p
          className="fontSize-12 text-center"
          style={{ color: type === "submissionsPage" ? "#f37955" : "white" }}
        >
          History
        </p>
      </div>

      <div
        className="text-center mt-1"
        onClick={() => navigate("/profile")}
        style={{ width: "20%" }}
      >
        <ProfileOutlined
          style={{
            fontSize: "28px",
            color: type === "profilePage" ? "#f37955" : "white",
          }}
        />
        <p
          className="fontSize-12 text-center"
          style={{ color: type === "profilePage" ? "#f37955" : "white" }}
        >
          Profile
        </p>
      </div>
    </div>
  );
};

export default UserFooter;
