import "../../css/Home.css";
import { useEffect, useState } from "react";
import MainHeader from "../../components/MainHeader";
import ScrollableImages from "../../components/ScrollableImages";
import UserFooter from "../../components/UserFooter";
import home from "../../images/homePage.svg";
import { UserOutlined } from "@ant-design/icons";
import axiosClient from "../../utils/axiosClient";
import { Modal, message } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  //   const dispatch = useDispatch();
  const [userData, setUserData] = useState();
  //   const [points, setPoints] = useState();
  const [error, setError] = useState();

  const token = localStorage.getItem(`user-token`);
  const [searchParams] = useSearchParams();
  const _code = searchParams.get("code");
  const _referCode = searchParams.get("referCode");

  const [qrCode, setQrCode] = useState(null);

  useEffect(() => {
    if (!token) {
      navigate("/login" + window.location.search);
    }
  }, [token]);

  // save code to localstorage
  useEffect(() => {
	  if (_code && _code !== "null" && _code !== "undefined") {
		  let scanCode = _code
		if (scanCode.length > 8 && scanCode.split(';').length > 1) {
			scanCode = scanCode.split(';')[0]
		  }
      localStorage.setItem("JOMKIK_SCAN_CODE", scanCode);
    }
  }, [_code]);
	
	useEffect(() => {
			if (_referCode && _referCode !== "null" && _referCode !== "undefined") {
	  localStorage.setItem("JOMKIK_REFER_CODE", _referCode);
	}			
	}, [_referCode])
	

  const check = async () => {
    if (!token) {
      return;
    }

    try {
      const res = await axiosClient.get("/users/details");
      //   console.log("res",res);
      setUserData(res.data.data.personalInfo);
    } catch (error) {
      setError(error.res.data.error);
      console.error("error", error);
    }
  };

  const handleRedeemCode = (code) => {
    if (!code || !token) {
      console.error("Invalid code");
      return;
    }

    setQrCode(code);

    // delay one sec
    setTimeout(() => {
      axiosClient
        .post(`/code/redeem`, { code })
        .then((res) => {
          message.success("Successfully redeem code " + code);
		  check();
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message.error(error.response.data.message);
          } else {
            message.error("Unable to redeem code " + code);
          }
        })
        .finally(() => {
          localStorage.removeItem("JOMKIK_SCAN_CODE");
          setQrCode(null);
          navigate("/home");
        });
    }, 1000);
  };

  // redeem code from localstorage
  useEffect(() => {
    const saveCode = localStorage.getItem("JOMKIK_SCAN_CODE");
    if (
      saveCode &&
      saveCode !== "null" &&
      saveCode !== "undefined" &&
      saveCode !== ""
    ) {
      handleRedeemCode(saveCode);
    }
  }, []);

  useEffect(() => {
    check();
  }, [axiosClient]);

  if (!token) {
    return <></>;
  }

  return (
    <div style={{ paddingBottom: "20%" }}>
      <Modal
        open={qrCode}
        centered
        footer={null}
        width={300}
        closable={false}
        onCancel={() => {}}
      >
        <p className="text-center">
          Verifying code: <code>{qrCode}</code> ...
        </p>
      </Modal>

      <div>
        <MainHeader />
      </div>
      {/* <div
				style={{ position: 'relative', top: '20px', left: '0', width: '90%' }}
			>
				<Whatsapp />
			</div> */}

      <div className="mt-1">
        <img
          src={home}
          alt="home_page_img"
          style={{
            width: "100%",
            height: "170px",
            objectFit: "cover",
          }}
        />
      </div>

      <div className="ml-2 mr-2" style={{ marginTop: "-5%" }}>
        <div className="user-home-container">
          <div className="text-center" style={{ paddingTop: "5%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "0.25rem",
              }}
            >
              <UserOutlined />
              <span className="font-weight-600" style={{ fontSize: "20px" }}>
                {userData?.name}
              </span>
            </div>
            <p className="text-center" style={{ fontSize: "14px" }}>
              @{userData?.referralCode}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              gap: "5rem",
              alignItems: "center",
              margin: "auto",
              paddingTop: "8%",
            }}
            className="mr-2 text-center"
          >
            <div>
              <p style={{ fontSize: "18px" }}>Token</p>
              <h1 style={{ fontSize: "18px" }}>
                {" "}
                {userData?.totalTokenBalance || 0}{" "}
              </h1>
            </div>
            <div>
              <p style={{ fontSize: "18px" }}>KiKs</p>
              <h1 style={{ fontSize: "18px" }}>
                {" "}
                {userData?.totalUnitsBalance || 0}{" "}
              </h1>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "18px",
            marginTop: "5%",
          }}
        >
          {/* <div
						className="box-container-left"
						onClick={() => navigate('/kikshop')}
					>
						<h3 className="mt-1 ml-1">
							Browse All <br />
							Rewards
						</h3>
						<img
							src={user1}
							alt=""
							style={{
								position: 'absolute',
								top: '-15px',
								right: '-60px',
								width: '100%',
								'@media (minWidth: 1000px)': {
									width: '30%',
								},
							}}
						/>
					</div> */}
          {/* <div className="box-container-right" onClick={() => setHowTo(true)}>
						<h3 className="mt-1 ml-1">
							How To Earn
							<br />
							Points?
						</h3>
						<img
							src={user2}
							alt=""
							style={{
								position: 'absolute',
								top: '-15px',
								right: '-15px',
								width: '45%',
								'@media (minWidth: 1000px)': {
									width: '30%',
								},
							}}
						/>
					</div> */}
        </div>

        {/* <div
					className="store-container"
					style={{
						display: 'flex',
						marginTop: '5%',
						justifyContent: 'space-evenly',
						alignItems: 'center',
					}}
					onClick={() => navigate('/stores')}
				>
					<img
						alt=""
						src={storeImg}
						style={{ width: '20%', height: 'auto', marginBottom: '15px' }}
						className=""
					/>
					<p style={{ fontSize: '20px', fontWeight: '500' }}>
						Check Participating Stores
					</p>
				</div> */}

        {/* <div
					className="ad-container mt-1"
					style={{ fontSize: '18px', alignItems: 'center', marginLeft: '40px' }}
				>
					<div>
						<h2
							className="t-black"
							style={{
								fontSize: '25px',
								position: 'relative',
								left: '10px',
								top: '30px',
							}}
						>
							What's New
						</h2>
					</div>
					<div
						style={{
							width: '50%',
							position: 'relative',
							right: '20px',
							top: '10px',
						}}
					>
						<img src={user3} alt="" style={{ width: '100%' }} />
					</div>
				</div> */}

        {/* <div className="carousel-container">
					<div onClick={() => navigate('/article')} className="mt-2">
						<img src={carousel1} alt="" className="w-100" />
					</div>
				</div> */}

        {/* <div className="text-center mt-3">
					<div className="star-brands-position">
						<img src={starBrands} alt="" style={{ width: '65%' }} />
					</div>
					<div className="star-brands-container">
						<div className="row">
							<a
								href="https://shopee.com.my/gskmalaysia.os?shopCollection=156916955#product_list"
								target="_blank"
								rel="noreferrer"
							>
								<img src={store1} alt="sdsdzxz" className="w-100" />
							</a>
							<a
								href="https://shopee.com.my/gskmalaysia.os?shopCollection=11742674#product_list"
								target="_blank"
								rel="noreferrer"
							>
								<img src={store2} alt="scotts" className="w-100" />
							</a>
							<a
								href="https://shopee.com.my/gskmalaysia.os?shopCollection=11742663#product_list"
								target="_blank"
								rel="noreferrer"
							>
								<img src={store3} alt="sensodyne" className="w-100" />
							</a>
						</div>
						<div className="row">
							<a
								href="https://shopee.com.my/gskmalaysia.os?shopCollection=27141662#product_list"
								target="_blank"
								rel="noreferrer"
							>
								<img src={store4} alt="centrum" className="w-100" />
							</a>
							<a
								href="https://shopee.com.my/gskmalaysia.os?shopCollection=27141557#product_list"
								target="_blank"
								rel="noreferrer"
							>
								<img src={store5} alt="caltrate" className="w-100" />
							</a>
							<a
								href="https://shopee.com.my/gskmalaysia.os?shopCollection=11742703#product_list"
								target="_blank"
								rel="noreferrer"
							>
								<img src={store6} alt="polident" className="w-100" />
							</a>
						</div>
					</div>
				</div> */}

        {/* <div className="submission-card mt-5">
					<div className="header-card">
						<p
							className="t-black fontSize-1"
							style={{ marginTop: '2%', marginLeft: '3%' }}
						>
							Every Purchase Gets
						</p>
					</div>

					<div className="mid-header-container t-white">
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div
								style={{
									width: '50%',
									lineHeight: '40px',
									justifyContent: 'center',
								}}
								className="mt-2"
							>
								<h1 style={{ fontSize: '48px' }} className="text-center">
									RM 5
								</h1>
								<p style={{ fontSize: '11px' }} className="text-center">
									Min. Spend of RM 38
								</p>
							</div>

							<div style={{ justifyContent: 'center' }}>
								<img src={line} alt="" style={{ height: '100%' }} />
							</div>

							<div className="d-flex rewards-position">
								<img
									src={tng}
									alt="tng"
									style={{ width: '35%', objectFit: 'contain' }}
								/>
								<img
									src={grab}
									alt="grab"
									style={{ width: '40%', objectFit: 'contain' }}
								/>
							</div>
						</div>
					</div>

					<div className="t-black mt-2">
						<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
							<div>
								<img
									src={icon1}
									alt="upload"
									className="ml-1"
									style={{ width: '10%' }}
								/>
								<span
									className="fontSize-18"
									style={{
										fontWeight: '800',
										position: 'relative',
										bottom: '5px',
									}}
								>
									{' '}
									My Submissions
								</span>
							</div>
							<div>
								<p
									className="fontSize-12"
									style={{ textAlign: 'left', fontWeight: '300' }}
								>
									Month:<b> {getCurrMonth}</b>
								</p>
							</div>
						</div>
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
						{uploadHistoryItemsToRender.map((_, index) => {
							const entry = uploadHistory[index];
							const receipt = entry?.receipts?.[0];

							const statusImgSrc = {
								APPROVED: approvedPic,
								COMPLETED: approvedPic,
								PENDING: pendingPic,
								CANCELLED: rejectedPic,
								REJECTED: rejectedPic,
							};

							const statusText = {
								APPROVED: 'Approved',
								COMPLETED: 'Approved',
								PENDING: 'Pending',
								CANCELLED: 'Rejected',
								REJECTED: 'Rejected',
							};

							const entryStatusTitle = {
								APPROVED: 'Approved',
								COMPLETED: 'Approved',
								PENDING: 'Pending Validation',
								CANCELLED: 'Rejected',
								REJECTED: 'Rejected',
							};

							const entryStatusDescription = {
								APPROVED: `Congratulations! Your receipt has been successfully approved, and you have been credited with ${
									receiptData?.amount || 'N/A'
								} points for this purchase.`,
								COMPLETED: `Congratulations! Your receipt has been successfully approved, and you have been credited with ${
									receiptData?.amount || 'N/A'
								} points for this purchase.`,
								PENDING:
									'Your submission is currently under review by our team. Please check back later for updates.',
								CANCELLED:
									'Oops! Your submission has been rejected due to a blurry image of the receipt. Please resubmit a clear photo of the receipt for verification.',
								REJECTED:
									'Oops! Your submission has been rejected due to a blurry image of the receipt. Please resubmit a clear photo of the receipt for verification.',
							};


							return (
								<div className="text-center mt-1" key={index}>
									<img
										alt=""
										src={
											receipt && statusImgSrc[receipt?.status]
												? statusImgSrc[receipt?.status]
												: uploadNew
										}
										style={{ width: '50px' }}
										onClick={() => {
											checkReceiptStatus(receipt ? receipt : 'new');
										}}
									/>
									<p className="font-weight-700 text-center">
										{receipt ? statusText[receipt?.status] : 'Upload New'}
									</p>

									<div>
										<Modal
											open={receiptData && receiptModal}
											centered
											header={null}
											footer={null}
											closable={false}
											width={320}
										>
											<div className="text-center">
												<img
													src={receiptData?.imageUrl}
													alt="uploadpic"
													style={{ width: '50%' }}
												/>
												<h2>
													{receiptData
														? entryStatusTitle[receiptData?.status]
														: ''}
												</h2>
												<hr
													style={{
														backgroundColor: '#30EA03',
														height: '3px',
														border: 'none',
														margin: 'auto',
														width: '35%',
													}}
												/>
												<div className="submit-pending-modal-font mt-1">
													<p className="fontSize-12 text-center">
														{receiptData
															? entryStatusDescription[receiptData?.status]
															: ''}
													</p>
												</div>

												{receiptData?.status === 'REJECTED' && (
													<Button
														onClick={() =>
															receiptData?.status === 'REJECTED'
																? navigate('/upload', {
																		state: {
																			receiptData: {
																				id: receiptData.campaignEntryId,
																			},
																		},
																  })
																: navigate('/upload')
														}
														className="mt-2"
														style={{
															backgroundColor: 'black',
															color: 'white',
															width: '90%',
															border: 'none',
															borderRadius: ' 5px',
															height: '35px',
															fontWeight: '600',
														}}
													>
														Resubmit
													</Button>
												)}
												<Button
													className="coming-soon-button mt-1"
													onClick={() => {
														setReceiptData();
														setReceiptModal(false);
													}}
												>
													Close
												</Button>
											</div>
										</Modal>
									</div>
								</div>
							);
						})}
					</div>
				</div> */}

        <div>
          <div
            // style={{ display: 'flex', justifyContent: 'space-between' }}
            className="mt-2"
          >
            <h2 className="text-center">Featured Game</h2>
            <h2
              style={{
                color: "grey",
                fontSize: "18px",
                marginTop: "3px",
                textAlign: "right",
              }}
              onClick={() => navigate("/game")}
            >
              View All
            </h2>
          </div>
          <ScrollableImages />
        </div>
      </div>
      <div style={{ justifyContent: "center", display: "flex" }}>
        <UserFooter type={"homePage"} />
      </div>
    </div>
  );
};

export default Home;
