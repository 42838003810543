import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LogoutOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { message } from "antd";
// import cart from "../images/shoppingcart.svg";
// import sideBarLogo from "../images/sideBarLogo.svg";
import backArrow from "../images/backArrow.png";
import { useDispatch } from "react-redux";
import { checkUserSession, logoutUser } from "../reducer/userReducer";
// import ComingSoon from "./Modal/ComingSoon";
import axiosClient from "../utils/axiosClient";
import { objectToURLSearchParamsString } from "../utils/axiosHelper";

const SubHeader = ({ setPreviousPage, forceRenderKey }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [open, setOpen] = useState(false);
  // const [selectedLanguage, setSelectedLanguage] = useState("");
  const localtoken = localStorage.getItem("user-token");
  // const [isSoon, setIsSoon] = useState(false);

  const [userCarts, setUserCarts] = useState([]);
  const fetchUserCarts = () => {
    const query = {
      rewardTypes: ["VOUCHER", "PRODUCT"],
    };

    const stringQuery = objectToURLSearchParamsString(query);

    axiosClient
      .get("/carts?" + stringQuery)
      .then((result) => {
        if (result?.data?.data) {
          Array.isArray(result.data.data) && setUserCarts(result.data.data);
        } else {
          console.error("result: ", result);
        }
      })
      .catch((err) => {
        console.error("fetchUserCarts: ", err);
        message.error(err?.message || "Error occur on fetching carts");
      });
  };

  const logOut = () => {
    dispatch(logoutUser());
    navigate("/login");
  };

  // const showDrawer = () => {
  //   setOpen(true);
  // };
  // const onClose = () => {
  //   setOpen(false);
  // };

  // const handleLanguageClick = (language) => {
  //   setSelectedLanguage(language);
  // };

  useEffect(() => {
    if (forceRenderKey && forceRenderKey !== 0) {
      fetchUserCarts();
    }
  }, [forceRenderKey]);

  useEffect(() => {
    fetchUserCarts();

    const check = async () => {
      try {
        dispatch(checkUserSession({ token: localtoken }));
      } catch (error) {
        // navigate(`/login?store=${store}`);
      }
    };
    check();
  }, []);

  return (
    <div>
      <div className="mt-2">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ justifyContent: "left" }}>
            <img
              src={backArrow}
              alt=""
              className="ml-2"
              onClick={() => navigate(setPreviousPage)}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginRight: "20px",
              gap: "15px",
              alignItems: "center",
            }}
          >
            <div>
              <ShoppingCartOutlined
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "23px",
                  color: "#474545",
                }}
                onClick={() => navigate("/cartInfo")}
              />
            </div>
            {/* {userCarts.length > 0 ? (
							<div>
								<Badge
									count={userCarts.length}
									style={{ backgroundColor: '#30E700', color: 'black' }}
								>
									<Avatar
										src={cart}
										alt=""
										className="ml-1"
										style={{ objectFit: 'contain', width: 'auto' }}
										onClick={() => navigate('/cartInfo')}
									/>
								</Badge>
							</div>
						) : (
							<Avatar
								src={cart}
								alt=""
								className="ml-1"
								style={{ width: 'auto', objectFit: 'contain' }}
								onClick={() => navigate('/cartInfo')}
							/>
						)} */}
            <div>
              <LogoutOutlined
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "20px",
                  color: "#474545",
                }}
                // onClick={showDrawer}
                onClick={logOut}
              />
            </div>
          </div>
        </div>
      </div>
      {/* 
      <Drawer
        title={
          <div style={{ display: "flex" }}>
            <div>
              <Button
                type="text"
                icon={<MenuUnfoldOutlined style={{ fontSize: "20px" }} />}
                onClick={onClose}
                alt=""
                style={{
                  backgroundColor: "white",
                  // position: 'relative',
                  // right: '30px',
                }}
              />
            </div>
            <img
              src={"sideBarLogo"}
              alt="Jomkik logo"
              style={{
                textAlign: "center",
                position: "relative",
                right: "10px",
              }}
            />
          </div>
        }
        placement="right"
        onClose={onClose}
        open={open}
        closable={false}
        width={200}
        style={{ borderBottom: "none" }}
      >
        <div style={{ lineHeight: "2.5" }}>
          <p
            className="fontSize-14"
            onClick={() => navigate("/help", { state: "FAQ" })}
          >
            FAQ
          </p>
          <p
            className="fontSize-14"
            onClick={() => navigate("/help", { state: "TNC" })}
          >
            Term & Condition
          </p>
          <p className="fontSize-14 t-black">
            <a
              className="t-black"
              target="_blank"
            //   href="https://www.privacy.haleon.com/en-my/general/general-full-text/"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </p>
          <p className="fontSize-14" 
		//   onClick={() => setIsSoon(true)}
		  >
            Contact Us
          </p>
          <p className="fontSize-14" onClick={() => logOut()}>
            Log Out
          </p>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
            position: "absolute",
            bottom: "20px",
            left: "50px",
            fontWeight: "600",
          }}
        >
          <p
            className="fontSize-14"
            style={{
              textDecoration: selectedLanguage === "ENG" && "underline",
            }}
            onClick={() => handleLanguageClick("ENG")}
          >
            ENG
          </p>
          <p>|</p>
          <p
            className="fontSize-14"
            style={{ textDecoration: selectedLanguage === "BM" && "underline" }}
            onClick={() => handleLanguageClick("BM")}
          >
            BM
          </p>
          <p>|</p>
          <p
            className="fontSize-14"
            style={{ textDecoration: selectedLanguage === "CN" && "underline" }}
            onClick={() => handleLanguageClick("CN")}
          >
            CN
          </p>
        </div>
      </Drawer> */}

      {/* <ComingSoon isSoon={isSoon} setIsSoon={setIsSoon} /> */}
    </div>
  );
};

export default SubHeader;
