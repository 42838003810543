import "../../css/Register.css";

import { Button, Checkbox, Form, Input, Modal } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import Tnc from "../../images/pdf/JomKiK Lunar Luck Bonanza Spin and Win T&C and Privacy.pdf";
import axiosClient from "../../utils/axiosClient";
import rejected from "../../images/rejected.png";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";

const RegisterForm = ({ formConfig }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [storeId] = useLocalStorage("storeId");
  const [tempOtp, setTempOtp] = useLocalStorage("tempOtp");
  const [loading, setLoading] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [error, setError] = useState(false);
  const [register, setRegister] = useState(false);
  const [errorEmail, setErrorEmail] = useState(" ");
  const [email, setEmail] = useState("");

	const referralCode = localStorage.getItem('JOMKIK_REFER_CODE');

  useEffect(() => {
    if (location.state?.phone) {
      form.setFieldsValue({ phone: "60" + location.state?.phone });
    }
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const register = {
        name: values.name,
        phone: "60" + location.state?.phone,
        email: values.email,
        companyId: process.env.REACT_APP_COMPANY_ID,
        storeId: storeId ? storeId : null,
        referralCode: referralCode || null,
        otp: tempOtp,
        // identity: localStorage.getItem("identity"),
      };

      const res = await axiosClient.post("/auth/register-otp", register);
      if (res) {
        setTempOtp(null);
        // setUserToken(res.data.token);
        localStorage.setItem("user-token", res.data.token);
        setRegister(true);
        navigate("/home"+window.location.search);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err.response.data.message);
      setErrorModal(true);
    }
  };

  if (!formConfig) return null;
  // get the form input value
  // const [form] = Form.useForm();

  const regexName = /^[a-zA-Z ]+( [a-zA-Z ]+)*$/;


  return (
    <>
      <Form
        form={form}
        onFinish={handleSubmit}
        onFinishFailed={(e) => console.error("submit fail", e.errorFields)}
        // disabled={loading}
        aria-label="form"
        autoComplete="off"
        scrollToFirstError
      >
        <div style={{ padding: "0 5%", width: "auto" }}>
          <Form.Item
            name="name"
            hasFeedback
            rules={[
              {
                required: true,
                message: "this is required",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && value?.length > 0) {
                    if (regexName.test(value)) {
                      return Promise.resolve(true);
                    }
                    return Promise.reject(new Error("Invalid name format!"));
                  }
                  return Promise.resolve(true);
                },
              }),
            ]}
          >
            <Input
              type="text"
              name="name"
              //   prefix="*"
              placeholder="Enter Full Name"
              style={{
                borderRadius: "10px",
                border: "2px solid black",
                backgroundColor: "transparent",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                color: "black",
                // height: "45px",
                // prefix: { color: "red" },
              }}
              required={true}
            />
          </Form.Item>

          <Form.Item
            name="email"
            validateDebounce={500}
            hasFeedback
            rules={[{ type: "email", message: "Invalid email format!" }]}
          >
            <Input
              type="email"
              name="email"
              placeholder="Enter Email Address"
              style={{
                borderRadius: "10px",
                border: "2px solid black",
                backgroundColor: "transparent",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                color: "black",
                // height: "45px",
              }}
              required={true}

            />
          </Form.Item>

          <Form.Item
            name="phone"
            validateDebounce={500}
            hasFeedback
            rules={[{ type: "phone", message: "Invalid phone format!" }]}
          >
            <Input
              type="tel"
              name="phone"
              placeholder="Enter Phone number"
              style={{
                borderRadius: "10px",
                border: "2px solid black",
                backgroundColor: "transparent",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                color: "black",
                // height: "45px",
              }}
              disabled={true}
            />
          </Form.Item>
        </div>

        <div className="text-center">
          <div className="mt-2 ml-1" style={{ maxWidth: "100%" }}>
            <div style={{ display: "flex" }}>
              <Checkbox
                required
                className="ml-1 t-black tnc transparent-checkbox"
              >
                <p
                  className="fontSize-14"
                  style={{
                    lineHeight: "18px",
                    fontWeight: "400",
                    maxWidth: "100%",
                    textAlign: "left",
                    margin: "0",
                  }}
                >
                  I agree to Jomkik's{" "}
                  <a
                    className="t-black text-deco-underline"
                    href={Tnc}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms & Conditions and Privacy Policy
                  </a>
                </p>
              </Checkbox>
            </div>
          </div>
          {/* <div className="mt-2 ml-2" style={{ maxWidth: "100%" }}>
            <div style={{ display: "flex" }}>
              <Checkbox
                required
                className="ml-1 t-black tnc transparent-checkbox"
              >
                <p
                  className="fontSize-14"
                  style={{
                    lineHeight: "18px",
                    fontWeight: "400",
                    maxWidth: "100%",
                    textAlign: "left",
                    margin: "0",
                  }}
                >
                  I authorize Jomkik to share my personal
                </p>
                <p
                  className="fontSize-14"
                  style={{
                    lineHeight: "18px",
                    fontWeight: "400",
                    maxWidth: "100%",
                    textAlign: "left",
                    margin: "0",
                  }}
                >
                  data* with selected third parties to provide
                </p>
                <p
                  className="fontSize-14"
                  style={{
                    lineHeight: "18px",
                    fontWeight: "400",
                    maxWidth: "100%",
                    textAlign: "left",
                    margin: "0",
                  }}
                >
                  value-added services as a Jomkik's member.
                </p>
              </Checkbox>
            </div>
          </div> */}
        </div>

        {error ? (
          <div style={{ color: "red", textAlign: "center" }}>{error}</div>
        ) : null}

        <div style={{ width: "auto" }}>
          <Form.Item className="form-input-box">
            <Button
              htmlType="submit"
              block
              loading={loading}
              className="mb-3 mt-2"
              style={{
                backgroundColor: "#264c96",
                width: "80%",
                margin: "10% auto",
                border: "none",
                fontWeight: "600",
                textDecoration: "none",
                color: "white",
              }}
            >
              SUBMIT
            </Button>
          </Form.Item>
        </div>
      </Form>

      <div>
        <Modal
          open={register}
          centered
          footer={null}
          // closable={false}
          onCancel={() => setErrorModal(false)}
          width={300}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={rejected} alt="" style={{ width: "20%" }}></img>
          </div>
          <br />
          <div
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              textAlign: "center",
            }}
          >
            {/* Invalid QR Code, please rescan a validate QR again */}
            {error}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default RegisterForm;
