import { useRef, useEffect, useState, useCallback } from "react";
// import { io } from "socket.io-client";
import { Icon } from "@iconify-icon/react";
import lightningBoltCircle from "@iconify-icons/mdi/lightning-bolt-circle";
import lightningBolt from "@iconify-icons/mdi/lightning-bolt";
import { Modal, Button } from "antd";
import UserFooter from "../../components/UserFooter";
import { ScanOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import rejected from "../../images/rejected.png";
import jsQR from "jsqr";
import axiosClient from "../../utils/axiosClient";
// import queryString from "query-string";
import successImg from "../../images/approved.png";
const fullWidth = window.innerWidth;
const fullHeight = window.innerHeight;
const margin = (fullWidth * 10) / 100;

const TestScan = () => {
  const canvasRef = useRef();
  const video = useRef();
  const crop = useRef(false);
  // const testCanvas = useRef();
  //   const [socket, setSocket] = useState(null);
  // const socketRef = useRef(null);
  const [constraint, setConstraint] = useState(null);
  const [videoDevices, setVideoDevices] = useState([]);
  const [torch, setTorch] = useState(false);
  const [flash, setFlash] = useState(false);
  const [choose, setChoose] = useState(false);
  const navigate = useNavigate();
  // const [inputValue, setInputValue] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [qrCodeVerified, setQrCodeVerified] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [allowScanning, setAllowScanning] = useState(true);
  const [successMsg, setSuccessMsg] = useState();
const [qrCodeProcessed, setQrCodeProcessed] = useState(false);
const [code, setCode] = useState();
const [isScanned, setIsScanned] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const tick = async (result) => {
  //   if (result && allowScanning) {
  //       setAllowScanning(false);
  //     const urlArray = result.split("?");
  //     const { code } = queryString.parse(urlArray[1]);
  //     try {
  //       const res = await axiosClient.post("/code/redeem", {
  //         code: code,
  //       });
  //       if (res && res.data) {
  //         setInputValue(code);
  //         setQrCodeVerified(true);
  //         setSuccessMsg(res.data.message ? res.data.message : "SUCCESS");
  //       } else {
  //         setErrorModal(true);
  //         setAllowScanning(false);
  //       }
  //     } catch (error) {
  //       console.error("error", error);
  //       setErrorModal(true);
  //       setErrorMsg(error.response.data.message);
  //       setAllowScanning(false);
  //     }
  //   }
  // };

  const checkQrCode = async (_code) => {
    if(isScanned){
      return
    }
    try {
      const res = await axiosClient.post("/code/redeem", {
        code: _code,
      });
      if (res.data?.data?.isScaned) {
        // setInputValue(_code);
        setQrCodeVerified(true);
        setSuccessMsg(res.data.message ? res.data.message : "SUCCESS");
        // setQrCodeProcessed(true);
      } else {
        setErrorMsg("Unable redeem the code, please contact admin");
        setErrorModal(true);
      }
    } catch (error) {
      console.error("error", error);
      setErrorModal(true);
      setErrorMsg(error.response.data.message);
    }

      setCode(null);
      setIsScanned(true)
  }
  
  useEffect(() => {
    if(code) {
      // wait 5seconds before calling checkQrCode
      // setTimeout(() =>
      checkQrCode(code)
      // , 5000)



    }
  }, [code]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  const tick = useCallback(async (result) => {
    if (result && allowScanning && !qrCodeProcessed||!isScanned) {
      setAllowScanning(false);
//       let params = new URL(document.location).searchParams;
// let name = params.get("name");
     const query  = new URL(result).searchParams
      let scanCode = query.get('code');
      
      if (scanCode.length > 8 && scanCode.split(';').length > 1) {
        scanCode = scanCode.split(';')[0]
      }

      if(!scanCode) {
        setErrorMsg("invalud code");
        setErrorModal(true)
        return;
      } else if(!code){
          setCode( scanCode);
      }

      // try {
      //   const res = await axiosClient.post("/code/redeem", {
      //     code: code,
      //   });
      //   if (res && res.data) {
      //     setInputValue(code);
      //     setQrCodeVerified(true);
      //     setSuccessMsg(res.data.message ? res.data.message : "SUCCESS");
      //     setQrCodeProcessed(true);
      //   } else {
      //     setErrorModal(true);
      //   }
      // } catch (error) {
      //   console.error("error", error);
      //   setErrorModal(true);
      //   setErrorMsg(error.response.data.message);
      // }
    }
  },[code,isScanned]);

  const drawFrame = useCallback(() => {
    if (
      video &&
      video.current &&
      video.current.readyState === video.current.HAVE_ENOUGH_DATA
    ) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const img = video.current;
      const sx = img.videoWidth / 2 - fullWidth / 2;
      const sy = img.videoHeight / 2 - fullHeight / 2;
      const sw = fullWidth;
      const sh = fullHeight;
      const dx = 0;
      const dy = 0;
      const dw = fullWidth;
      const dh = fullHeight;

      ctx.drawImage(img, sx, sy, sw, sh, dx, dy, dw, dh);
      const boxY = fullHeight / 2 - fullWidth / 2;
      const boxW = fullWidth - margin * 2;
      const boxH = boxW;

      ctx.beginPath();
      ctx.fillStyle = "#274193";
      ctx.fillRect(0, 0, fullWidth, boxY);
      ctx.stroke();

      ctx.beginPath();
      ctx.fillStyle = "#274193";
      ctx.fillRect(0, boxY - 2, margin, boxH + 4);
      ctx.stroke();

      ctx.beginPath();
      ctx.fillStyle = "#274193";
      ctx.fillRect(boxW + margin, boxY - 2, margin, boxH + 4);
      ctx.stroke();

      ctx.beginPath();
      ctx.fillStyle = "#274193";
      ctx.fillRect(0, boxY + boxH, fullWidth, fullHeight - boxY - boxH);
      ctx.stroke();

      if (!crop.current) {
        crop.current = true;
        // testCanvas.current.width = boxW;
        // testCanvas.current.height = boxH;
        requestAnimationFrame(drawCrop);
      }
      requestAnimationFrame(drawFrame);
    } else requestAnimationFrame(drawFrame);
  }, [crop, video]);

  const drawCrop = useCallback(() => {
    if (
      crop.current &&
      video &&
      video.current &&
      video.current.readyState === video.current.HAVE_ENOUGH_DATA
    ) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = canvasRef.current;
      const sx = 0 + margin;
      const sy = fullHeight / 2 - fullWidth / 2;
      const sw = fullWidth - margin * 2;
      const sh = sw;
      canvas.width = sw;
      canvas.height = sh;
      const dx = 0;
      const dy = 0;
      const dw = canvas.width;
      const dh = canvas.height;
      ctx.drawImage(img, sx, sy, sw, sh, dx, dy, dw, dh);
      const imageData = ctx.getImageData(0, 0, dw, dh);
      const code = jsQR(imageData.data, imageData.width, imageData.height);
      if (code && code.data && allowScanning) {
        setAllowScanning(false);
        tick(code.data);
      } else {
        requestAnimationFrame(drawCrop);
      }
    } else {
      setAllowScanning(true);
      requestAnimationFrame(drawCrop);
    }
  }, [allowScanning, tick]);

  const handleFlash = useCallback(
    (val) => {
      if (torch && flash !== val && video && video.current) {
        const track = video.current.srcObject.getVideoTracks()[0];
        track.applyConstraints({
          advanced: [
            {
              torch: val,
            },
          ],
        });
        setFlash(val);
      }
    },
    [torch, flash, video]
  );

  // get constraints
  useEffect(() => {
    (async () => {
      // full hd
      let width = 1920;
      let height = 1080;
      // quad hd
      // let width = 2560;
      // let height = 1440;
      // 4k
      //   let width = 3840;
      //   let height = 2160;
      if (fullHeight > fullWidth) {
        // portrait
        width = 1080;
        height = 1920;
      }
      try {
        let videoConstraints = {};
        const constraints = navigator.mediaDevices.getSupportedConstraints();
        if (constraints && constraints.width)
          videoConstraints.width = { ideal: width };
        if (constraints && constraints.height)
          videoConstraints.height = { ideal: height };
        if (constraints && constraints.frameRate)
          videoConstraints.frameRate = { ideal: 60 };
        if (constraints && constraints.facingMode)
          videoConstraints.facingMode = "environment";
        if (constraints && constraints.zoom) videoConstraints.zoom = true;
        if (constraints && constraints.torch) videoConstraints.torch = true;
        const setThis = {
          video: videoConstraints,
          audio: false,
        };
        setConstraint(setThis);
      } catch (error) {
        alert(JSON.stringify(error.message));
      }
    })();
  }, []);

  // get available devices
  useEffect(() => {
    (async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        const setThis = videoDevices.map((device, index) => ({
          deviceId: device.deviceId,
          groupId: device.groupId,
          kind: device.kind,
          label: device.label ? device.label : `Camera ${index + 1}`,
        }));
        setVideoDevices(setThis);
      } catch (error) {
        alert(error.message);
      }
    })();
  }, []);

  // start camera
  useEffect(() => {
    if (constraint) {
      (async () => {
        try {
          crop.current = false;
          cancelAnimationFrame(drawFrame);
          cancelAnimationFrame(drawCrop);
          if (video.current) {
            video.current.srcObject.getTracks().forEach((x) => x.stop());
            video.current.srcObject = null;
            video.current.src = null;
            video.current.pause();
            video.current.remove();
            video.current = null;
          }

          const myVideo = document.createElement("video");
          myVideo.setAttribute("playsinline", true);
          myVideo.setAttribute("muted", true);
          myVideo.setAttribute("autoplay", true);

          const stream = await navigator.mediaDevices.getUserMedia(constraint);
          myVideo.srcObject = stream;
          myVideo.play();

          const track = myVideo.srcObject.getVideoTracks()[0];
          const settings = track.getSettings();
          if ("torch" in settings) setTorch(true);

          myVideo.onplaying = () => {
            video.current = myVideo;
            canvasRef.current.width = fullWidth;
            canvasRef.current.height = fullHeight;
            setChoose(false);
            requestAnimationFrame(drawFrame);
            myVideo.remove();
          };
        } catch (error) {
          alert(error.message);
        }
      })();
    }
  }, [constraint, video, drawFrame, setTorch, setChoose, drawCrop]);

  const switchCamera = useCallback(
    (deviceId) => {
      (async () => {
        try {
          const newConstraint = {
            ...constraint,
            video: {
              ...constraint.video,
              deviceId: { exact: deviceId },
            },
          };

          crop.current = false;
          cancelAnimationFrame(drawFrame);
          cancelAnimationFrame(drawCrop);
          if (video.current) {
            if (
              video.current.srcObject &&
              video.current.srcObject.getTracks().length
            )
              video.current.srcObject.getTracks().forEach((x) => x.stop());
            if (video.current.srcObject) video.current.srcObject = null;
            if (video.current.src) video.current.src = null;
            video.current.pause();
            video.current.remove();
            video.current = null;
          }

          const myVideo = document.createElement("video");
          myVideo.setAttribute("playsinline", true);
          myVideo.setAttribute("muted", true);
          myVideo.setAttribute("autoplay", true);

          const stream = await navigator.mediaDevices.getUserMedia(
            newConstraint
          );
          myVideo.srcObject = stream;
          myVideo.play();

          const track = myVideo.srcObject.getVideoTracks()[0];
          const settings = track.getSettings();

          if ("torch" in settings) setTorch(true);

          myVideo.onplaying = () => {
            video.current = myVideo;
            canvasRef.current.width = fullWidth;
            canvasRef.current.height = fullHeight;
            requestAnimationFrame(drawFrame);
            myVideo.remove();
            setChoose(false);
          };
        } catch (error) {
          alert("Switch Error: " + error.message);
        }
      })();
    },
    [constraint, drawFrame, drawCrop]
  );

  const handleModalCancel = () => {
    setErrorModal(false);
    setIsScanned(false)
    setAllowScanning(true);
    requestAnimationFrame(drawCrop);
    requestAnimationFrame(drawFrame);
  };

  useEffect(() => {
    return () => {
      if (
        qrCodeVerified ||
        window.location.pathname === "/rewards" ||
        window.location.pathname === "/home" ||
        window.location.pathname === "/profile" ||
        window.location.pathname === "/submissions"
      ) {
        crop.current = false;
        cancelAnimationFrame(drawFrame);
        cancelAnimationFrame(drawCrop);
        if (video.current) {
          video.current.srcObject.getTracks().forEach((x) => x.stop());
          video.current.srcObject = null;
          video.current.src = null;
          video.current.pause();
          video.current.remove();
          video.current = null;
        }
      }
    };
  }, [video, drawFrame, setTorch, setChoose, drawCrop, qrCodeVerified]);

  return (
    <div
      style={{
        position: "relative",
        padding: "0 0 100px 0",
        backgroundColor: "#274193",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          margin: 0,
          padding: 0,
          overflow: "hidden",
          position: "relative",
          color: "white",
          height: "100%",
        }}
      >
        <canvas ref={canvasRef} width={fullWidth} height={fullHeight}></canvas>

        <div
          style={{
            position: "absolute",
            top: "6vh",
            left: 0,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "5%",
            alignItems: "center",
          }}
        >
          <ScanOutlined style={{ color: "white", fontSize: "25px" }} />

          <p
            style={{
              margin: 0,
              padding: 0,
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            Scan QR Code
          </p>
        </div>

        <div
          style={{
            position: "absolute",
            top: "14vh",
            left: 0,
            width: "100%",
            textAlign: "center",
          }}
        >
          <p style={{ margin: 0, padding: 0 }}>
            Position the QR code within the frame
          </p>
        </div>

        {torch ? (
          <div
            style={{
              position: "absolute",
              bottom: "25%",
              left: 0,
              width: "100%",
              textAlign: "center",
            }}
          >
            {flash ? (
              <Icon
                icon={lightningBolt}
                width="32"
                onClick={() => handleFlash(false)}
              />
            ) : (
              <Icon
                icon={lightningBoltCircle}
                width="32"
                onClick={() => handleFlash(true)}
              />
            )}
          </div>
        ) : null}

        {videoDevices.length && videoDevices.length > 1 ? (
          <div
            style={{
              position: "absolute",
              bottom: "12%",
              left: 0,
              width: "100%",
              textAlign: "center",
            }}
          >
            <p style={{ margin: 0, padding: 0 }}>
              Can't scan the QR Code?{" "}
              <u onClick={() => setChoose(true)}>Try Different Camera</u>
            </p>
          </div>
        ) : null}

        {/* <div style={{ border: "1px black solid", display: "flex", flexDirection: "column", justifyContent: "center" }}>
					{videoDevices.length
						? videoDevices.map(device => (
								<button type="button" key={device.deviceId} onClick={() => switchCamera(device.deviceId)} style={{ margin: "10px 0" }}>
									{device.label}
								</button>
						))
						: null}
				</div> */}
        {/* <div>
					<canvas style={{ border: "1px blue solid" }} ref={testCanvas}></canvas>
				</div> */}
      </div>

      <UserFooter />

      <Modal
        open={choose}
        onCancel={() => setChoose(false)}
        centered
        footer={null}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "25px",
          }}
        >
          {videoDevices.map((device) => (
            <button
              type="button"
              key={device.deviceId}
              style={{ width: "100%", margin: "5px 0" }}
              onClick={() => switchCamera(device.deviceId)}
            >
              {device.label}
            </button>
          ))}
        </div>
      </Modal>

      <Modal
        open={qrCodeVerified}
        centered
        footer={null}
        closable={false}
        width={300}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={successImg} alt="" style={{ width: "20%" }} />
        </div>
        <br />
        <div
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          {successMsg}
        </div>
        <Button
          onClick={() => navigate("/submissions")}
          style={{
            backgroundColor: "#274193",
            fontWeight: "600",
            fontSize: "12px",
            width: "100%",
            marginTop: "5%",
            color: "white",
          }}
        >
          Check your rewarded token!
        </Button>
      </Modal>

      <Modal
        open={errorModal}
        centered
        footer={null}
        closable={true}
        onCancel={handleModalCancel}
        width={300}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={rejected} alt="" style={{ width: "20%" }} />
        </div>
        <br />
        <div
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          {errorMsg
            ? errorMsg
            : "Invalid QR Code, please rescan a validate QR again"}
        </div>
      </Modal>
    </div>
  );
};

export default TestScan;
