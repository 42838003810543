import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN_URL,
  headers: {
    "x-company-id": process.env.REACT_APP_COMPANY_ID,
  },
});

axiosClient.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("user-token");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    console.log("error", error);
    return Promise.reject(error);
  }
);

export default axiosClient;
