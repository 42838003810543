import React from "react";
import { Button } from "antd";
import successImg from "../../images/successImg.svg";
import { useNavigate } from "react-router-dom";

const SummarySuccess = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        backgroundColor: "#274193",
        height: "100%",
        width: "100%",
        padding: "0",
        margin: "0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        color:'white'
      }}
    >
      <div style={{ minHeight: "100vh", paddingTop: "20%" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={successImg}
            alt=""
            style={{ objectFit: "fill", width: "50%" }}
          />
        </div>
        <div className="text-center mt-1 t-white">
          <h1 className="">Success!</h1>
          <h3 className="mt-2 fontSize-16">
            You have successfully completed your
            <br /> redemption.{" "}
          </h3>
        </div>
        <div
          className="mt-5"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <p
            className="t-white fontSize-12"
            style={{ maxWidth: "80%", lineHeight: "20px" }}
          >
            <b>E-Voucher Redemption</b>: Access your e-voucher in the "Rewards"
            section.
          </p>
        </div>
        <div
          style={{
            paddingBottom: "100px",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Button
            style={{
              backgroundColor: "#f37955",
              borderRadius: "5px",
              width: "85%",
              border: "none",
              height: "45px",
              padding: "12px 40px",
              fontSize: "16px",
              fontWeight: "700",
              marginTop: "100px",
              color:'white',
            }}
            onClick={() => navigate("/rewards")}
          >
            Go To Rewards
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SummarySuccess;
