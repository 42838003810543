import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { userReducer } from './reducer/userReducer';
import { BrowserRouter } from 'react-router-dom';
import { userDetailsReducer } from './reducer/userDetailsReducer';
import reduxStore from './redux/reduxStore';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
	<Provider store={reduxStore}>
  <App />
  </Provider>
</BrowserRouter>
);

serviceWorkerRegistration.unregister();
reportWebVitals();
