import "../../css/Reward.css";
import { Button, Input, Space } from "antd";
import React, { useEffect, useState } from "react";
import MainHeader from "../../components/MainHeader";
import { SearchOutlined } from "@ant-design/icons";
import UserFooter from "../../components/UserFooter";
import axiosClient from "../../utils/axiosClient";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";
import { objectToURLSearchParamsString } from "../../utils/axiosHelper";
const PointShop = () => {
  const navigate = useNavigate();
  const [searchByName, setSetSearchByName] = useState(null);
  const [filterBy, setFilterBy] = useState();
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [shopItems, setShopItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [userTotalUnitsBalance, setUserTotalUnitsBalance] = useState(0);

  const getShopItemsWithQueries = (
    _searchByName,
    _filterBy,
    _selectedCategory
  ) => {
    const query = {
      // DEFAULT:
      rewardTypes: ["VOUCHER","PRODUCT"], //only show purchase able rewards

      // CUSTOM FILTER:
      ...(_searchByName && { name: _searchByName }),
      ...(filterBy && { filterBy: _filterBy }),
      ...(_selectedCategory &&
        _selectedCategory !== "All" && {
          category: _selectedCategory,
        }),
    };

    const stringQuery = objectToURLSearchParamsString(query);
    axiosClient
      .get("/shop-items?" + stringQuery)
      .then((res) => res.data)
      .then(({ data }) => {
        if (data) {
          Array.isArray(data?.shopItems) && setShopItems(data?.shopItems);
          Array.isArray(data?.categories) && setCategories(data?.categories);

          data.userTotalUnitsBalance &&
            setUserTotalUnitsBalance(Number(data.userTotalUnitsBalance));
        }
      })
      .catch((err) => {
        console.error("getShopItemsWithQueries: ", err);
      });
  };

  useEffect(() => {
    getShopItemsWithQueries(searchByName, filterBy, selectedCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchByName, filterBy, selectedCategory]);

  const handleOnSelectFilterBy = (_filterBy) => {
    setFilterBy(_filterBy);
  };

  const handleOnSelectCategory = (_cate) => {
    setSelectedCategory(_cate);
  };

  const handleOnSearchByName = debounce(
    (_search) => setSetSearchByName(_search),
    500
  );

  return (
    <div>
      <div>
        <MainHeader />
      </div>

      <div className="ml-2 mr-2">
        <div
          className="mt-5"
          style={{
            justifyContent: "space-evenly",
            display: "flex",
            gap: "8px",
          }}
        >
          <Button
            className="btn-color"
            style={{
              backgroundColor: "#274193",
              color: "white",
            }}            onClick={() => navigate("/kikshop")}
          >
            {" "}
            Kik Shop
          </Button>

          <Button
            className="btn-color"
            style={{ backgroundColor: "#C2C2C2", color: "white" }}
            onClick={() => navigate("/rewards")}
          >
            {" "}
            My Rewards
          </Button>
        </div>

        <div>
          <div className="mt-3">
            <Space.Compact style={{ width: "100%" }}>
              <Input
                size="small"
                prefix={<SearchOutlined />}
                placeholder=" Search for a reward"
                onChange={(e) => {
                  handleOnSearchByName(e.target.value);
                }}
                style={{
                  fontSize: "16px",
                  color: "black",
                }}
                className="search-bar"
              />
            </Space.Compact>
          </div>

          {/* <div
						className="mt-2 scroll-img"
						style={{ justifyContent: 'left', display: 'flex', gap: '8px' }}
					>
						{['All', ...categories].map((category, index) => {
							return (
								<Button
									key={index}
									className={
										selectedCategory === category
											? 'redeemItem-active-btn'
											: 'redeemItem-inactive-btn'
									}
									style={{ flex: 1, minWidth: '100px' }}
									// onClick={() => handleOnSelectCategory(category)}
								>
									{category}
								</Button>
							);
						})}
					</div> */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            {/* <div> */}
            <div
              style={{
                borderRadius: "10px",
                background: "#E0E7EC",
                width: userTotalUnitsBalance <= 100000 ? "100px" : "150px",
                height: "35px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>
                <b>{userTotalUnitsBalance || 0}</b> KiKs
              </p>
            </div>
            {/* </div> */}
            {/* <div>
							<Select
								placeholder="Filter By"
								className="select-tag text-center"
								style={{ textAlign: 'center', width: '145px' }}
								// onChange={handleOnSelectFilterBy}
								// dropdownClassName="select-dropdown"
								popupClassName="select-dropdown"
							>
								<Select.Option key="1" value={'isRecommended'}>
									Recommended
								</Select.Option>
								<Select.Option key="2" value={null}>
									Clear Filter
								</Select.Option>
							</Select>
						</div> */}
          </div>
          {/* <div
						style={{
							position: 'relative',
							top: '20px',
							left: '0',
							width: '90%',
						}}
					>
						<Whatsapp />
					</div> */}

          <div
            className="pointShop-container mt-1"
            style={{ paddingBottom: "100px" }}
          >
            {shopItems.map(
              ({ id, name, priceValue, imageUrl, reward }, index) => {
                const handleClick = () => {
                  navigate("/rewards/items/" + id);
                };

                return (
                  <div
                    className="text-center mt-1"
                    key={index}
                    onClick={handleClick}
                  >
                    <div
                      className="d-flex"
                      style={{
                        borderRadius: "20px",
                        display: "flex",
                        border: "1px solid #D9D9D9",
                        height: "133px",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "8px",
                      }}
                    >
                      <img
                        src={reward?.imageUrl}
                        alt=""
                        style={{ width: "70%", objectFit: "contain" }}
                      />
                    </div>
                    <div style={{ textAlign: "left", marginTop: "5px" }}>
                      <h2 className="fontSize-20">{name}</h2>
                      <h2 className="fontSize-16">{priceValue} KiKs</h2>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>

      {/* Footer */}
      <UserFooter type={"rewardsPage"} />
    </div>
  );
};

export default PointShop;
