import { useEffect, useState } from "react";
import SubHeader from "../../components/SubHeader";
import UserFooter from "../../components/UserFooter";
import notAvailableImg from "../../images/imageUnavailable.jpeg";
import { useNavigate } from "react-router-dom";
import axiosClient from "../../utils/axiosClient";
import spinWheel from "../../images/SpinWheel.png";

const Game = () => {
  const [displayItem, setDisplayItem] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGameDetails = async () => {
      try {
        const res = await axiosClient.get("/campaigns", {
          params: { type: "GAMES" },
        });
        if (res && res.data) {
          // console.log(res.data);
          setDisplayItem(res.data);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchGameDetails();
  }, []);

  return (
    <div style={{ paddingBottom: "20%" }}>
      <SubHeader setPreviousPage={"/home"} />
      <div
        className="text-center"
        style={{
          fontSize: "30px",
          borderRadius: "8px",
          backgroundColor: "#274193",
          color: "white",
          margin: "5% 30% 5% 30%",
          height: "40px",
        }}
      >
        Games
      </div>
      {displayItem && displayItem.length
        ? displayItem.map((ele, index) => {
            if (ele.deletedAt) {
              return null;
            }
            return (
              <div key={index}>
                <div
                  style={{ paddingBottom: "5%" }}
                  onClick={() => navigate("/game/" + ele.id)}
                >
                  <div
                    style={{
                      boxShadow: "0px 2px 18px rgba(0, 0, 0, 0.16)",
                      borderRadius: "8px",
                      margin: "0 8% 0 8%",
                    }}
                  >
                    <div className="text-center" style={{ width: "100%" }}>
                      <div
                        className="submission-history-card"
                        style={{ height: "90px" }}
                      >
                        <div
                          className="d-flex"
                          style={{
                            alignItems: "center",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "left",
                              width: "30%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              style={{
                                width: "100px",
                                height: "90px",
                                objectFit: "contain",
                              }}
                              src={
                                ele.id ===
                                "d4077e42-2114-41f2-9280-de41ece1b73c"
                                  ? spinWheel
                                  : ele.imageUrl
                                  ? ele.imageUrl
                                  : notAvailableImg
                              }
                              alt=""
                            />
                          </div>
                          <div style={{ width: "50%" }} className="ml-1">
                            <h3
                              style={{
                                fontWeight: "600",
                                fontFamily: "Brandon Grotesque, Arial",
                                fontSize: "14px",
                                textAlign: "left",
                              }}
                            >
                              {ele.name}
                            </h3>
                            {/* <h3
                              style={{
                                fontWeight: "400",
                                fontFamily: "Brandon Grotesque, Arial",
                                fontSize: "10px",
                                textAlign: "left",
                              }}
                            >
                              {ele.description || "No description yet"}
                            </h3> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : null}
      <UserFooter type={"game"} />
    </div>
  );
};

export default Game;
