import "../../css/Cart.css";

import { Button, Modal, Rate, message } from "antd";
import React, { useEffect, useState } from "react";
import { redirect, useNavigate, useParams } from "react-router-dom";

import SubHeader from "../../components/SubHeader";
import axiosClient from "../../utils/axiosClient";

const RewardItems = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [showMsg, setShowMsg] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState("Product");
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [shopItem, setShopItem] = useState(null);

  const getShopItem = (_id) => {
    axiosClient
      .get("/shop-items/" + _id)
      .then((res) => {
        if (res?.data?.data) {
          setShopItem(res.data.data);
          // console.log(shopItem);
        }
      })
      .catch((err) => {
        console.error("getShopItem: ", err);
        redirect("/kikshop");
      });
  };

  useEffect(() => {
    if (id) {
      getShopItem(id);
    } else {
      navigate("/kikshop");
    }

    // dispatch(checkUserSession({ token: localtoken }));
  }, [id]);

  const handleAddToCart = () => {
    // Prevent multiple clicks while the action is processing
    if (isAddingToCart) {
      return;
    }

    const params = {
      shopItemId: shopItem.id,
      qty: 1,
    };

    axiosClient
      .post("/carts", params)
      .then((res) => {
        setShowMsg(true);
        setTimeout(() => {
          setShowMsg(false);

          setTimeout(() => {
            navigate("/cartInfo");
          }, 500);
        }, 1000);
      })
      .catch((err) => {
        console.error("handleAddToCart: ", err);
        message.error(
          err?.response?.data?.message || "Error occur on add to cart."
        );
      })
      .finally(() => setIsAddingToCart(false));
  };

  const handleToggleType = (newType) => {
    setType(newType);
  };

  // obtain the average rating for each physical item
  // const calculateAverageRating = () => {
  // 	if (shopItem && shopItem?.itemReview?.length > 0) {
  // 		const totalRating = shopItem.itemReview.reduce(
  // 			(acc, item) => acc + item.rating,
  // 			0
  // 		);

  // 		return totalRating / shopItem.itemReview.length;
  // 	}

  // 	return 0;
  // };

  const stockBalance = shopItem?.reward?.qtyMax - shopItem?.reward?.qtyUsed;
  const isOutOfStock = stockBalance <= 0 ? true : false;

  return (
    <div style={{height:'100vh'}}>
      <div>
        <SubHeader setPreviousPage={-1} />
      </div>
      {/* <div style={{ position: 'relative', top: '20px' }}>
				<Whatsapp />
			</div> */}
      <div
        style={{
          backgroundColor: "#F2F2F2",
          justifyContent: "center",
          display: "flex",
        }}
        className="mt-3"
      >
        <div className="mt-2 text-center" style={{ paddingBottom: "10px" }}>
          <img
            src={shopItem?.reward?.imageUrl}
            alt=""
            style={{ width: "120px" }}
          />
        </div>
      </div>

      <div className="ml-2 mr-2 mt-2" style={{ paddingBottom: "50px" }}>
        <div>
          <p style={{ fontSize: "24px", fontWeight: "700" }}>
            {shopItem?.name}
          </p>
        </div>

        {/* { shopItem?.name === "itemCategory" ? 
                    <div>
                        {shopItem.product.pickup_optional === "Y" ? 
                            <div>
                              <div style={{ borderRadius:'4px', backgroundColor:'#F5F5F5', display: 'flex', justifyContent:'center', width: '180px', alignItems:'center', gap:'5px' }} className='mt-1'>
                                  <img src={delivery} alt="" style={{width:'30px'}}/>
                                  <p className='fontSize-14' style={{fontWeight:'400'}}>Available for pick-up</p> 
                              </div>
                              <div className='mt-1'>
                                  <Rate value={foundRating.rating/foundRating.length}/>
                                  <span style={{ color: '#777'}} className='fontSize-16 ml-1'>{ foundRating.rating && <span className="ant-rate-text">{`${val[foundRating.rating/foundRating.length - 1]}/5 Stars`}</span> }</span>
                              </div> 
                            </div>
                        :
                            <div>
                              <div style={{ borderRadius:'4px', backgroundColor:'#F5F5F5', display: 'flex', justifyContent:'center', width: '130px', alignItems:'center', gap:'5px' }} className='mt-1'>
                                  <img src={delivery} alt="" style={{width:'30px'}}/>
                                  <p className='fontSize-14' style={{fontWeight:'400'}}>Delivery Only</p>
                              </div>
                              <div className='mt-1'>
                              <Rate value={averageRating}/>
                                <span style={{ color: '#777'}} className='fontSize-16 ml-1'>
                                   { `${averageRating}/5 Stars`}
                                </span>
                              </div> 
                            </div>

                        }
                    </div>
                : 
                    null 
                } */}
        {/* {shopItem?.reward?.type === 'PRODUCT' && (
					<div>
						<div>
							{shopItem?.reward?.isShippable && (
								<div
									style={{
										borderRadius: '4px',
										backgroundColor: '#F5F5F5',
										display: 'flex',
										justifyContent: 'center',
										width: '130px',
										alignItems: 'center',
										gap: '5px',
									}}
									className="mt-1"
								>
									<img src={delivery} alt="" style={{ width: '30px' }} />
									<p className="fontSize-14" style={{ fontWeight: '400' }}>
										Delivery Only
									</p>
								</div>
							)}
							<div className="mt-1">
								<Rate value={calculateAverageRating()} disabled/>
								<span style={{ color: '#777' }} className="fontSize-16 ml-1">
									{`${calculateAverageRating().toFixed(1)}/5 Stars`}
								</span>
							</div>
						</div>
					</div>
				)} */}

        <div
          className="mt-1 fontSize-16"
          style={{
            fontWeight: "400",
            color: "#424242",
          }}
        >
          {/* {shopItem?.descriptionTnc?.length
						? React.Children.toArray(shopItem.descriptionTnc.map((_s) => <p>{_s}</p>))
						: 'No description for now'} */}
          {/* {shopItem?.descriptionTnc?.length
						? React.Children.toArray(shopItem.descriptionTnc.map((_s) => <p>{_s}</p>))
						: 'No description for now'} */}
        </div>

        <div className="mt-2">
          <div style={{ justifyContent: "left", display: "flex" }}>
            <div>
              <p
                style={{
                  color: type === "Product" ? "black" : "#777",
                  fontWeight: "600",
                }}
                className="fontSize-16"
                onClick={() => handleToggleType("Product")}
              >
                Product Information
              </p>
            </div>

            {/* {shopItem?.reward?.type === 'VOUCHER' ? (
							<p
								style={{ color: '#777' }}
								className="fontSize-16"
								onClick={() => handleToggleType('TNC')}
							>
								T&C
							</p>
						) : (
							<p
								style={{
									color: type === 'Reviews' ? 'black' : '#777',
									fontWeight: '600',
								}}
								className="fontSize-16"
								onClick={() => handleToggleType('Reviews')}
							>
								Reviews
							</p>
						)} */}
            {/* <p
              style={{ color: "#777" }}
              className="fontSize-16"
              onClick={() => handleToggleType("TNC")}
            >
              T&C
            </p> */}
          </div>

          {type === "Product" && (
            <div>
              <ol
                style={{
                  padding: "0 20px",
                  fontSize: "14px",
                  textAlign: "justify",
                  textJustify: "auto",
                  fontWeight: "400",
                  color: "#424242",
                  marginBottom: "60px",
                }}
              >
                <li className="my-1">
                  Go to your shopping cart and select your purchases to "Check
                  Out".
                </li>
                <li className="my-1">At the Checkout page.</li>
                <li className="my-1">
                  Enter your promotion code on the Gift Card in the "Enter
                  Voucher Code" field and tap on the "Apply" button to redeem
                  your Gift Card.
                </li>
                <li className="my-1">
                  Once applied, continue to check out by tapping on the "Proceed
                  to Checkout" button for the website or "Place Order" button
                  for the app to continue the payment and complete the purchase.
                </li>
              </ol>
            </div>
          )}

          {/* {type === 'Reviews' && (
						<div>
							{shopItem && shopItem?.itemReview?.length ? (
								shopItem?.itemReview.map((ele, index) => {
									return (
										<div
											style={{
												borderRadius: '8px',
												border: '0.5px solid #D8D8D8',
												padding: '12px 16px',
												alignItems: 'center',
											}}
											key={index}
										>
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-between',
												}}
											>
												<div>
													<p
														style={{ fontWeight: '600' }}
														className="fontSize-16"
													>
														{ele.companyUser.name}
													</p>
													<p
														style={{ color: '#7C878E', fontWeight: '400' }}
														className="fontSize-16"
													>
														{ele.createdAt}
													</p>
													<p
														style={{ fontWeight: '400' }}
														className="fontSize-16"
													>
														{ele.comment}
													</p>
												</div>
												<div
													style={{
														display: 'flex',
														justifyContent: 'right',
														alignItems: 'center',
														width: '35%',
														gap: '5px',
													}}
												>
													<img
														src={star}
														alt="ratingStar"
														style={{ width: '20%' }}
													/>
													<p
														style={{
															fontWeight: '640000',
															fontSize: '24px',
														}}
													>
														{ele.rating}
													</p>
												</div>
											</div>
										</div>
									);
								})
							) : (
								<p>No review yet.</p>
							)}
						</div>
					)} */}
        </div>

        <div>
          {/* <div>
                  <img src={circle} alt="circle" style={{width:'80px'}}/>
                </div> */}
        </div>
      </div>

      {!isOutOfStock ? (
        <div className="add-to-cart-container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div className="ml-3">
              <span style={{ fontSize: "40px", fontWeight: "700" }}>
                {shopItem?.priceValue}
              </span>
              <span
                style={{
                  fontWeight: "none",
                  marginLeft: "5px",
                  fontSize: "22px",
                }}
              >
                KiKs
              </span>
            </div>
            <div className="mr-2">
              <Button
                style={{
                  opacity: isAddingToCart ? "0.5" : "1",
                  backgroundColor: "#274193",
                  border: "none",
                  borderRadius: "5px",
                  fontWeight: "500",
                }}
                className="fontSize-16 text-center t-white"
                onClick={() => {
                  setIsAddingToCart(true);
                  handleAddToCart();
                }}
              >
                {isAddingToCart ? "Adding..." : "Add To Cart"}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="add-to-cart-container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div className="ml-3">
              <span style={{ fontSize: "45px", fontWeight: "700" }}>
                {shopItem?.priceValue}
              </span>
              <span
                style={{
                  fontWeight: "none",
                  marginLeft: "5px",
                  fontSize: "24px",
                }}
              >
                KiKs
              </span>
            </div>
            <div className="mr-2">
              <Button
                style={{
                  backgroundColor: "#274193",
                  border: "none",
                  borderRadius: "5px",
                  fontWeight: "500",
                  color: "grey",
                }}
                className="fontSize-16 text-center"
                disabled
              >
                Out Of Stock
              </Button>
            </div>
          </div>
        </div>
      )}

      {showMsg && (
        <div
          style={{
            position: "fixed",
            bottom: "170px",
            left: "50%",
            transform: "translateX(-50%)",
            padding: "8px 20px",
            backgroundColor: "#ABEA9A",
            color: "#2B7218",
            borderRadius: "22px",
            boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.12)",
            textAlign: "center",
          }}
          className="add-to-cart-width"
        >
          Added to cart successfully!
        </div>
      )}

      <div>
        <Modal
          open={isOpen}
          centered
          footer={null}
          width={350}
          onCancel={() => setIsOpen(false)}
        >
          <div className="text-center">
            <div className="mt-1">
              <p
                style={{
                  fontSize: "18px",
                  width: "auto",
                  fontWeight: "500",
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                Review(s)
              </p>

              <div className="mt-2">
                {shopItem && shopItem?.itemReview?.length > 0 ? (
                  shopItem.itemReview.map((ele, index) => {
                    const numbering = `${index + 1}. `;

                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="text-center">
                          {numbering}
                          {ele.comment}
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <p className="text-center">
                      Please check back the reviews later
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default RewardItems;
